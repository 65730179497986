import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { FormThemeMode } from '../../__generated__/graphql';
import { AppActionType, AppContextType } from '../../types/appContext.type';

export default function DetectSystemThemeMode() {
  const { dispatch } = useContext(AppContext) as AppContextType;
  useEffect(() => {
    // Check if the browser supports matchMedia
    if (window.matchMedia) {
      // Create a media query for dark mode
      const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

      // Function to update the state
      const updateDarkMode = (e: MediaQueryListEvent | MediaQueryList) => {
        dispatch({
          type: AppActionType.setSystemThemeMode,
          data: e.matches ? FormThemeMode.Dark : FormThemeMode.Light,
        });
      };

      // Set the initial state
      updateDarkMode(darkModeQuery);

      // Add an event listener to track changes
      darkModeQuery.addListener(updateDarkMode);

      // Clean up the listener when the component unmounts
      return () => darkModeQuery.removeListener(updateDarkMode);
    }
  }, []);
  return null;
}
