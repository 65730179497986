/* eslint-disable no-unused-vars */

import { REGEX } from '../../../../common/constants';

/* eslint-disable @typescript-eslint/no-explicit-any */
export type ValidationRule = (value: any) => string | null;

export const ValidationRules = {
  required: (value: any): string | null =>
    value ? null : 'This field is required',

  minLength:
    (length: number): ValidationRule =>
    (value: string): string | null =>
      value.length >= length ? null : `Minimum length is ${length} characters`,

  maxLength:
    (length: number): ValidationRule =>
    (value: string): string | null =>
      value.length <= length ? null : `Maximum length is ${length} characters`,

  pattern:
    (regex: RegExp, message: string): ValidationRule =>
    (value: string): string | null =>
      regex.test(value) ? null : message,

  email: (value: string): string | null =>
    REGEX.EMAIL.test(value) ? null : 'Invalid email address',

  number: (value: any): string | null =>
    !isNaN(parseFloat(value)) && isFinite(value) ? null : 'Must be a number',

  min:
    (min: number): ValidationRule =>
    (value: number): string | null =>
      value >= min ? null : `Minimum value is ${min}`,

  max:
    (max: number): ValidationRule =>
    (value: number): string | null =>
      value <= max ? null : `Maximum value is ${max}`,
};
