import { ValidationRule, ValidationRules } from '../ValidationRules';
import { FormField } from './FormField';

export class TimeField extends FormField {
  constructor(
    id: string,
    label: string,
    required: boolean,
    placeholder: string,
    public validations?: { min?: number; max?: number },
    validationRules: ValidationRule[] = [],
  ) {
    super(id, 'text', label, required, placeholder, validationRules);

    if (validations?.min) {
      this.validationRules.push(ValidationRules.minLength(validations.min));
    }
    if (validations?.max) {
      this.validationRules.push(ValidationRules.maxLength(validations.max));
    }
  }

  validate(value: string): string | null {
    for (const rule of this.validationRules) {
      const error = rule(value);
      if (error) return error;
    }
    return null;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      validations: this.validations,
    };
  }
}
