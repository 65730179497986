import { PlusCircle } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useEffect } from 'react';
import { ROUTES } from '../../../common/constants';
import useRouter from '../../../hooks/useRouter';

export default function CreateCard() {
  const { navigate } = useRouter();

  const handleClick = () => {
    navigate(ROUTES.FORM_TEMPLATES);
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Check if Windows key (metaKey) and 'T' are pressed
      if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
        event.preventDefault();
        handleClick();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  return (
    <div className="create-card" onClick={handleClick}>
      <Flex vertical gap={12} align="center">
        <PlusCircle color="var(--content-primary)" size={40} weight="fill" />
        <Flex gap={4} vertical>
          <Title
            level={4}
            className="mb-0 text-center text-content-primary font-secondary"
          >
            New Form
          </Title>
          <Paragraph className="mb-0 text-center text-content-secondary">
            (Ctrl/⌘ + K)
          </Paragraph>
        </Flex>
      </Flex>
    </div>
  );
}
