import { useMutation, useQuery } from '@apollo/client';
import { Sparkle } from '@phosphor-icons/react';
import { Col, Divider, Flex, Row, Segmented, Skeleton } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { lowerCase, map, startCase, times } from 'lodash';
import { useContext, useState } from 'react';
import { AppContext } from '../../AppContext';
import { FormCategory, FormThemeMode } from '../../__generated__/graphql';
import { ROUTES, getThemeBackground } from '../../common/constants';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { FormThemeType, Palette } from '../form-builder/model/form/form.types';
import { USE_FORM_TEMPLATE } from '../form/graphql/mutations';
import { GET_FORM_CATEGORIES_TEMPLATE } from '../form/graphql/queries';

type ExampleCardType = {
  title: string;
  description: string;
  btnTitle: string;
  id: string;
  activeDesign: Palette;
  type: FormThemeType;
};

export default function FormTemplates() {
  const { navigate } = useRouter();
  const [item, setItems] = useState<FormCategory[]>([]);
  const [activeItem, setActiveItem] = useState<string>();
  const {
    state: { systemThemeMode },
  } = useContext(AppContext) as AppContextType;
  const { loading } = useQuery(GET_FORM_CATEGORIES_TEMPLATE, {
    onCompleted: (res) => {
      setItems(res.formCategoriesWithTemplate?.data as FormCategory[]);
      setActiveItem(
        startCase(res.formCategoriesWithTemplate?.data?.[0]?.name || ''),
      );
    },
  });

  const ExampleCard = ({
    title,
    description,
    btnTitle,
    id,
    type,
    activeDesign,
  }: ExampleCardType) => {
    const [useFormTemplateMutate, { loading }] = useMutation(
      USE_FORM_TEMPLATE,
      { onError: () => {} },
    );
    return (
      <div
        className="example-card"
        style={getThemeBackground(type as FormThemeType, activeDesign)}
      >
        <Title
          level={4}
          className="example-card-title font-secondary semi-bold"
          style={{ color: activeDesign.textColor }}
        >
          {title}
        </Title>
        <Paragraph
          className="example-card-description text-meta text-neutrals-800"
          ellipsis={{
            rows: 3,
          }}
          style={{ color: activeDesign.textColor }}
        >
          {description}
        </Paragraph>
        <CommonButton
          size="small"
          onClick={() => {
            useFormTemplateMutate({
              variables: {
                data: {
                  formTemplateId: id,
                },
              },
              onCompleted: (res) => {
                navigate(
                  `${ROUTES.EDITOR}?id=${res.useFormTemplate?.data?.id}`,
                );
              },
            });
          }}
          loading={loading}
        >
          {btnTitle}
        </CommonButton>
      </div>
    );
  };

  const ExampleSkeletonCard = () => {
    return (
      <div className="example-card">
        <Skeleton active className="w-full mb-16" paragraph={{ rows: 2 }} />
        <Skeleton.Button active />
      </div>
    );
  };

  return (
    <div className="onboarding-wrapper">
      <section className="container">
        <Flex vertical gap={8}>
          <Title className="font-secondary text-center text-content-primary semi-bold">
            Choose a template
          </Title>
          <Flex vertical>
            <Paragraph className="text-description text-center text-content-secondary">
              Browse our templates to get started quickly and easily.
            </Paragraph>
            <Paragraph className="text-description text-center text-content-secondary mb-32">
              Find your perfect fit and simplify form-building!
            </Paragraph>
          </Flex>
        </Flex>
        <div className="flex justify-center mx-auto max-w-800">
          {activeItem && (
            <Segmented
              defaultValue={activeItem}
              className="app-segments mb-20"
              onChange={(value) => {
                setActiveItem(value);
              }}
              options={map(item, (val) => startCase(val.name!))}
            />
          )}
        </div>
        <Row gutter={[12, 12]}>
          {!loading
            ? item?.map(
                (item) =>
                  startCase(item.name!) === activeItem &&
                  item?.formTemplates?.map((template, idx) => {
                    const activeDesign = template?.form?.designs?.[0];

                    const mode =
                      activeDesign?.themeMode === FormThemeMode.Auto
                        ? systemThemeMode
                        : activeDesign?.themeMode;

                    const activeTheme: Palette =
                      activeDesign?.themeVariation?.colorPalette[
                        lowerCase(mode as FormThemeMode)
                      ];

                    return (
                      <Col span={24} md={8} key={idx}>
                        <ExampleCard
                          title={template?.form?.title || ''}
                          description={template?.form?.prompt || ''}
                          btnTitle="Use this"
                          id={template?.id || ''}
                          activeDesign={activeTheme}
                          type={
                            activeDesign?.themeVariation?.type as FormThemeType
                          }
                        />
                      </Col>
                    );
                  }),
              )
            : times(3, (idx) => {
                return (
                  <Col span={24} md={8} key={idx}>
                    <ExampleSkeletonCard />
                  </Col>
                );
              })}
        </Row>
      </section>
      <div className="max-divider">
        <Divider orientation="center">
          <span className="text-neutrals-600 text-meta">OR</span>
        </Divider>
      </div>
      <div className="m-auto">
        <CommonButton
          icon={<Sparkle size={16} weight="fill" />}
          size="large"
          type="primary"
          onClick={() => {
            navigate(ROUTES.FORM_CREATE);
          }}
          className="scratch-btn"
        >
          Create with AI
        </CommonButton>
      </div>
    </div>
  );
}
