import { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { AppContext } from './AppContext';
import { ROUTES } from './common/constants';
import { AppContextType } from './types/appContext.type';

const PrivateRoute = () => {
  const { getToken } = useContext(AppContext) as AppContextType;
  const idToken = getToken();
  return !idToken ? <Navigate to={ROUTES.LOGIN} /> : <Outlet />;
};
export default PrivateRoute;
