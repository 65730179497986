import { useQuery } from '@apollo/client';
import useQueryParams from '../../hooks/useQueryParams';
import FormLoading from '../form/editor/utils/FormLoading';
import { GET_RESULT_OVERVIEW } from '../form/graphql/queries';

export default function Overview() {
  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;

  const { loading, data } = useQuery(GET_RESULT_OVERVIEW, {
    variables: {
      where: {
        formId,
      },
      filter: {
        isTest: false,
      },
    },
    fetchPolicy: 'network-only',
  });

  if (loading) {
    return <FormLoading />;
  }
  return (
    <div className="result-container">
      <div className="overview-wrapper">
        <div className="overview-result mb-32">
          <div className="item">
            <h1 className="zinq-typography text-system-green-dark">
              {data?.formSubmissionOverview?.data?.completedSubmissions}
            </h1>
            <p className="text-base text-neutrals-6">Submissions</p>
          </div>
          <div className="item">
            <h1 className="zinq-typography text-blue">
              {data?.formSubmissionOverview?.data?.averageSubmissionTime?.toFixed(
                2,
              )}
            </h1>
            <p className="text-base text-neutrals-6">
              Average time to complete
            </p>
          </div>
          <div className="item">
            <h1 className="zinq-typography text-system-red-dark">
              {data?.formSubmissionOverview?.data?.incompleteSubmissions}
            </h1>
            <p className="text-base text-neutrals-6">Abandons</p>
          </div>
        </div>
        {/* commenting for release v.x */}
        {/* <div className="summary-wrapper">
          <h4 className="zinq-typography">Summary (4 responses) :</h4>
          <ul className="body-base text-content-secondary">
            <li>
              The onboarding experience received mixed ratings from 2 to 5.
              Job-specific training and meeting team members were found most
              helpful.
            </li>
            <li>Clarity of job responsibilities was generally rated high.</li>
            <li>
              Suggestions include providing more detailed HR policies and
              increasing team interaction.{' '}
            </li>
            <li>
              Overall, the onboarding was positive with some areas for
              improvement.
            </li>
          </ul>
        </div>
        <div className="summary-wrapper">
          <h4 className="zinq-typography">Audience insights: </h4>
          <Card
            type="inner"
            className="mb-16"
            title="High Ratings for Onboarding Experience"
          >
            <div className="body-small">Aakash and Shivansh (4)</div>
          </Card>
          <Card
            type="inner"
            className="mb-16"
            title="Job-Specific Training Most Helpful"
          >
            <div className="body-small">Jeet and Vandan</div>
          </Card>
          <Card
            type="inner"
            className="mb-16"
            title="Clear on Job Responsibilities"
          >
            <div className="body-small">Aakash and Purvam (5)</div>
          </Card>
          <Card type="inner" className="mb-16" title="Areas for Improvement">
            <div className="body-small">
              Detailed HR policies (Aakash), more team interaction (Shivansh),
              initial setup duration (Jeet), overall process pacing (Vandan)
            </div>
          </Card>
        </div> */}
      </div>
    </div>
  );
}
