import React, { useState } from 'react';
import { Form } from '../model/form/Form';
import { FormInput } from './form-fields/FormInput';

type DynamicFormProps = {
  form: Form;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onSubmit: (data: any) => void;
};

export const FormRenderer: React.FC<DynamicFormProps> = ({
  form,
  onSubmit,
}) => {
  const initialValues = form.fields.reduce(
    (acc, field) => {
      acc[field.id] = '';
      return acc;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    {} as Record<string, any>,
  );

  const [formData, setFormData] = useState(initialValues);
  const [errors, setErrors] = useState<Record<string, string | null>>({});

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (id: string, value: any) => {
    setFormData((prev) => ({ ...prev, [id]: value }));
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    const validationErrors = form.validate(formData);
    setErrors(validationErrors);

    if (Object.values(validationErrors).every((error) => error === null)) {
      onSubmit(formData);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h2>{form.title}</h2>
      {form.description && <p>{form.description}</p>}
      {form.fields.map((field) => (
        <div key={field.id}>
          <label htmlFor={field.id}>{field.label}</label>
          <FormInput
            field={field}
            value={formData[field.id]}
            onChange={handleChange}
            error={errors[field.id]}
          />
          {errors[field.id] && (
            <span className="error">{errors[field.id]}</span>
          )}
        </div>
      ))}
      <button type="submit">Submit</button>
    </form>
  );
};
