import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/common/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { LOGOUT_USER } from './graphql/mutations';

const Logout = () => {
  const { dispatch } = useContext(AppContext) as AppContextType;
  const { navigate } = useRouter();
  const [logoutMutate, { loading }] = useMutation(LOGOUT_USER, {
    onError: () => {
      dispatch({ type: AppActionType.logout });
      navigate(ROUTES.LOGIN);
    },
  });

  useEffect(() => {
    logoutMutate({
      onCompleted: () => {
        dispatch({ type: AppActionType.logout });
        navigate(ROUTES.LOGIN);
      },
    });
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default Logout;
