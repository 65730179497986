import ResizableLayout from '../../../components/layouts/ResizableLayout';
import FormPreviewMode from './FormPreviewMode';
import FormStructure from './FormStructure';

export default function Editor() {
  return (
    <section className="editor-wrapper">
      <ResizableLayout
        leftContent={<FormStructure />}
        rightContent={<FormPreviewMode showTabs />}
        minPercentage={40}
        maxPercentage={60}
      />
    </section>
  );
}
