import { Carousel, Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import Slide1 from '../../assets/images/ai-powered.png';
import Slide3 from '../../assets/images/powerful-and-fast.png';
import Slide2 from '../../assets/images/uniquely-yours.png';
import { SlideTypes } from './auth.types';

export default function AuthCarousel() {
  const Slide = ({ title, description, imgUrl }: SlideTypes) => {
    return (
      <Flex vertical gap={42} className="slide" justify="center" align="center">
        <Flex vertical gap={18}>
          <Title level={1} className="font-secondary">
            {title}
          </Title>
          <Paragraph className="mb-0 text-content-tertiary text-m medium text-description">
            {description}
          </Paragraph>
        </Flex>
        <Flex>
          <img src={imgUrl} />
        </Flex>
      </Flex>
    );
  };
  return (
    <Flex className="auth-carousel" justify="center" vertical>
      <Carousel autoplay swipeToSlide>
        <Slide
          title="AI-powered"
          description="AI-driven core, not an afterthought: Designed to capture what's essential."
          imgUrl={Slide1}
        />
        <Slide
          title="Uniquely yours"
          description="Gather inputs in style. Designed to stand out effortlessly."
          imgUrl={Slide3}
        />
        <Slide
          title="Powerful, simple & fast"
          description="Get a 'form' ready in seconds. Go from 'form' to 'publish' in a flash."
          imgUrl={Slide2}
        />
      </Carousel>
    </Flex>
  );
}
