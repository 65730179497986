import { Trash, X } from '@phosphor-icons/react';
import { Flex, Modal, ModalProps } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import React from 'react';
import CommonButton from '../primitives/CommonButton';

const CommonDeleteModal: React.FC<
  ModalProps & {
    content: string;
  }
> = ({
  open,
  onClose,
  onOk,
  title,
  content,
  footer,
  confirmLoading,
  cancelButtonProps,
}) => {
  const defaultFooter = () => (
    <Flex justify="flex-end" gap={8}>
      <CommonButton
        key="cancel"
        type="default"
        onClick={(e) => {
          if (onClose) {
            onClose(e);
          }
        }}
        {...cancelButtonProps}
      >
        Cancel
      </CommonButton>
      <CommonButton
        key="submit"
        type="text"
        onClick={onOk}
        className="text-danger-on-surface"
        loading={confirmLoading}
        icon={
          <Trash size={16} color="var(--danger-on-surface)" weight="regular" />
        }
      >
        Delete
      </CommonButton>
    </Flex>
  );

  return (
    <Modal
      title={
        <Flex align="center" justify="space-between" className="mb-16">
          <Paragraph className="mb-0 font-secondary text-lg semi-bold">
            {title || 'Delete Confirmation'}
          </Paragraph>
          <X
            size={24}
            color="var(--neutral-09)"
            className="cursor-pointer"
            onClick={(e) => {
              if (onClose) {
                onClose(e);
              }
            }}
          />
        </Flex>
      }
      open={open}
      centered
      closeIcon={null}
      onClose={onClose}
      footer={footer || defaultFooter}
    >
      <Paragraph className="mb-0 text-s medium text-content-secondary">
        {content}
      </Paragraph>
    </Modal>
  );
};

export default CommonDeleteModal;
