import { CrownSimple } from '@phosphor-icons/react';

export default function PremiumLogo() {
  return (
    <CrownSimple
      className="premium-logo"
      color="var(--premium-yellow)"
      size={14}
      weight="fill"
    />
  );
}
