import { ConfigProvider, Switch, SwitchProps, ThemeConfig } from 'antd';

type SwitchType = 'primary' | 'secondary';

export default function CommonSwitch({
  type,
  ...restProps
}: SwitchProps & { type: SwitchType }) {
  const config: Record<string, ThemeConfig> = {
    primary: {
      token: {
        colorPrimary: '#21282B',
        colorPrimaryHover: 'var(--surface-inverse-primary)',
      },
      components: {
        Switch: {
          handleBg: 'var(--surface-primary)',
        },
      },
    },
    secondary: {
      token: {
        colorPrimary: '#E5E8E9',
        colorPrimaryHover: 'var(--surface-primary)',
      },
      components: {
        Switch: {
          handleBg: 'var(--surface-primary)',
        },
      },
    },
  };

  return (
    <ConfigProvider theme={config?.[type]}>
      <Switch {...restProps} className={type} />
    </ConfigProvider>
  );
}
