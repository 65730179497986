import { useSearchParams } from 'react-router-dom';

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Function to convert URLSearchParams to an object without using iteration
  const getParamsAsObject = () => {
    const paramsObj: Record<string, string> = {};
    searchParams.forEach((value, key) => {
      paramsObj[key] = value;
    });
    return paramsObj;
  };

  // Function to update query params while preserving existing ones
  const updateQueryParams = (newParams: Record<string, string>) => {
    const currentParams = getParamsAsObject();

    const updatedParams = {
      ...currentParams,
      ...newParams, // Merge new params with existing
    };

    setSearchParams(updatedParams);
  };

  // Function to get the value of a specific query param
  const getQueryParam = (param: string) => {
    return searchParams.get(param);
  };

  return { updateQueryParams, getQueryParam };
};

export default useQueryParams;
