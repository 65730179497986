import { FormField, IFormField } from './fields/FormField';

export type IForm = {
  id: string;
  title: string;
  description?: string;
  fields: IFormField[];
};

export class Form implements IForm {
  constructor(
    // eslint-disable-next-line no-unused-vars
    public id: string,
    // eslint-disable-next-line no-unused-vars
    public title: string,
    // eslint-disable-next-line no-unused-vars
    public fields: FormField[],
    // eslint-disable-next-line no-unused-vars
    public description?: string,
  ) {}

  toJSON() {
    return {
      id: this.id,
      title: this.title,
      description: this.description,
      fields: this.fields.map((field) => field.toJSON()),
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  validate(values: { [fieldId: string]: any }): {
    [fieldId: string]: string | null;
  } {
    const errors: { [fieldId: string]: string | null } = {};

    for (const field of this.fields) {
      const value = values[field.id];
      if (field.required && (value === undefined || value === '')) {
        errors[field.id] = 'This field is required';
      } else if (value !== undefined && value !== '') {
        errors[field.id] = field.validate(value);
      }
    }
    return errors;
  }
}
