import { DatePicker, Input, Select, TimePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIME_FORMAT,
  DROPDOWN_LIST,
} from '../../../../common/constants';
import { CheckboxField } from '../../model/form/fields/CheckboxField';
import { ContactInfo } from '../../model/form/fields/ContactInfo';
import { ContactInfoField } from '../../model/form/fields/ContactInfoField';
import { DateField } from '../../model/form/fields/DateField';
import { EmailField } from '../../model/form/fields/EmailField';
import { FormField } from '../../model/form/fields/FormField';
import { NumberField } from '../../model/form/fields/NumberField';
import { PhoneField } from '../../model/form/fields/PhoneField';
import { PhoneNumber } from '../../model/form/fields/PhoneNumber';
import { SelectField } from '../../model/form/fields/SelectField';
import { TextField } from '../../model/form/fields/TextField';
import { TimeField } from '../../model/form/fields/TimeField';

type FormInputProps = {
  field: FormField;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any;
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  onChange: (id: string, value: any) => void;
  error: string | null;
};

export const FormInput: React.FC<FormInputProps> = ({
  field,
  value,
  onChange,
}) => {
  const handleValueChange = (value: string | Dayjs | boolean) => {
    onChange(field.id, value);
  };

  const handlePhoneChange = (
    part: 'countryCode' | 'number',
    newValue: string,
  ) => {
    const phoneNumber =
      value instanceof PhoneNumber ? value : new PhoneNumber('', '');
    const updatedPhoneNumber = new PhoneNumber(
      part === 'countryCode' ? newValue : phoneNumber.countryCode,
      part === 'number' ? newValue : phoneNumber.number,
    );
    onChange(field.id, updatedPhoneNumber);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleContactInfoChange = (subFieldId: string, subFieldValue: any) => {
    const contactInfo =
      value instanceof ContactInfo ? value : ContactInfo.empty();
    const updatedContactInfo = new ContactInfo(
      subFieldId === 'firstName' ? subFieldValue : contactInfo.firstName,
      subFieldId === 'lastName' ? subFieldValue : contactInfo.lastName,
      subFieldId === 'phoneNumber' ? subFieldValue : contactInfo.phoneNumber,
      subFieldId === 'email' ? subFieldValue : contactInfo.email,
      subFieldId === 'companyName' ? subFieldValue : contactInfo.companyName,
    );
    onChange(field.id, updatedContactInfo);
  };

  switch (field.constructor) {
    case TextField:
    case EmailField:
      return (
        <Input
          type={field.type}
          id={field.id}
          value={value}
          onChange={(e) => handleValueChange(e.target.value)}
          placeholder={field.placeholder}
        />
      );
    case NumberField:
      return (
        <Input
          type="number"
          id={field.id}
          value={value}
          onChange={(e) => handleValueChange(e.target.value)}
          placeholder={field.placeholder}
        />
      );
    case DateField:
      return (
        <DatePicker
          id={field.id}
          value={value && dayjs(value)}
          onChange={(date) =>
            handleValueChange(dayjs(date).format(DEFAULT_DATE_FORMAT))
          }
          placeholder={field.placeholder}
          format={DEFAULT_DATE_FORMAT}
        />
      );
    case TimeField:
      return (
        <TimePicker
          id={field.id}
          value={value && dayjs(value)}
          onChange={(time) =>
            handleValueChange(dayjs(time).format(DEFAULT_TIME_FORMAT))
          }
          placeholder={field.placeholder}
          format={DEFAULT_TIME_FORMAT}
        />
      );
    case SelectField:
      return (
        <Select
          id={field.id}
          value={value}
          onChange={(e) => handleValueChange(e.target.value)}
        >
          {(field as SelectField).options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </Select>
      );
    case CheckboxField:
      return (
        <input
          type="checkbox"
          id={field.id}
          checked={value}
          onChange={(e) => handleValueChange(e.target.checked)}
        />
      );
    case PhoneField:
      // eslint-disable-next-line no-case-declarations
      const phoneNumber =
        value instanceof PhoneNumber ? value : new PhoneNumber('', '');
      return (
        <div>
          <Select
            value={phoneNumber.countryCode}
            showSearch
            onChange={(value) => handlePhoneChange('countryCode', value)}
          >
            {DROPDOWN_LIST.countryList.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <Input
            type="tel"
            value={phoneNumber.number}
            onChange={(e) => handlePhoneChange('number', e.target.value)}
            placeholder={field.placeholder}
          />
        </div>
      );
    case ContactInfoField:
      // eslint-disable-next-line no-case-declarations
      const contactField = field as ContactInfoField;
      // eslint-disable-next-line no-case-declarations
      const contactInfo =
        value instanceof ContactInfo ? value : ContactInfo.empty();
      return (
        <div>
          <FormInput
            field={contactField.firstName}
            value={contactInfo.firstName}
            onChange={(_, v) => handleContactInfoChange('firstName', v)}
            error={null}
          />
          <FormInput
            field={contactField.lastName}
            value={contactInfo.lastName}
            onChange={(_, v) => handleContactInfoChange('lastName', v)}
            error={null}
          />
          <FormInput
            field={contactField.phoneNumber}
            value={contactInfo.phoneNumber}
            onChange={(_, v) => handleContactInfoChange('phoneNumber', v)}
            error={null}
          />
          <FormInput
            field={contactField.email}
            value={contactInfo.email}
            onChange={(_, v) => handleContactInfoChange('email', v)}
            error={null}
          />
          <FormInput
            field={contactField.companyName}
            value={contactInfo.companyName}
            onChange={(_, v) => handleContactInfoChange('companyName', v)}
            error={null}
          />
        </div>
      );
    default:
      return null;
  }
};
