import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const FormLayout = () => {
  return (
    <Layout>
      <Layout className="form-layout">
        <Content>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
export default FormLayout;
