import { Result } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES, TOAST_MESSAGE } from '../../common/constants';
import useQueryParams from '../../hooks/useQueryParams';
import CommonButton from '../primitives/CommonButton';

const ErrorPage = () => {
  const { getQueryParam } = useQueryParams();
  const errorText = getQueryParam('error') || TOAST_MESSAGE.somethingWentWrong;

  return (
    <Result
      status="500"
      // title="500"
      subTitle={errorText}
      extra={
        <Link type="primary" to={ROUTES.MAIN} replace>
          <CommonButton type="primary">Back Home</CommonButton>
        </Link>
      }
    />
  );
};

export default ErrorPage;
