import { ConfigProvider } from 'antd';
import { useContext, useState } from 'react';
import { AppContext } from '../../../../AppContext';
import useQueryParams from '../../../../hooks/useQueryParams';
import { AppContextType } from '../../../../types/appContext.type';
import EditShare from './EditShare';
import ShareList from './ShareList';

export const FormSharing = () => {
  const { getQueryParam } = useQueryParams();
  const [counter, setCounter] = useState(1);
  const [currentId, setCurrentId] = useState<string>('');
  const formId = getQueryParam('id') as string;
  const {
    dispatch,
    state: { formSettings: formData },
  } = useContext(AppContext) as AppContextType;

  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBorder: 'var(--border-primary)',
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <section className="zinq-form-builder-config">
          <div className="flex justify-center mx-auto flex-column">
            <div className="background-wrapper h-screen">
              {counter === 1 && (
                <ShareList
                  onBack={() => {
                    setCounter(2);
                  }}
                  onClick={(id: string) => {
                    setCurrentId(id);
                  }}
                />
              )}
              {counter === 2 && currentId && (
                <EditShare id={currentId} onBack={() => setCounter(1)} />
              )}
            </div>
          </div>
        </section>
      </ConfigProvider>
    </section>
  );
};
