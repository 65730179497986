import { useEffect } from 'react';
import version from '../../../package.json';

export default function AppVersion() {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('APP_VERSION', version?.version);
  }, []);
  return null;
}
