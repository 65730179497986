import { ValidationRule, ValidationRules } from '../ValidationRules';
import { TextField } from './TextField';

export class EmailField extends TextField {
  constructor(
    id: string,
    label: string,
    required: boolean,
    placeholder: string,
    validationRules: ValidationRule[] = [],
  ) {
    super(id, label, required, placeholder, undefined, [
      ValidationRules.email,
      ...validationRules,
    ]);
  }

  validate(value: string): string | null {
    for (const rule of this.validationRules) {
      const error = rule(value);
      if (error) return error;
    }
    return null;
  }
}
