import { PlusOutlined } from '@ant-design/icons';
import { Form, Image, Input, InputNumber, Rate, Select, Upload } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { FormFieldType } from '../auth/auth.types';
import { FormFieldProps } from './form.types';

export const FormField: React.FC<FormFieldProps> = ({
  name,
  label,
  rules,
  apiErrors,
  type,
  options,
  inputProps = {},
  formItemProps = {},
}) => {
  const uploadButton = (
    <button
      type="button"
      className="border-none bg-transparent flex flex-column items-center gap-8"
    >
      <PlusOutlined />
      <div>Upload</div>
    </button>
  );

  const renderInput = () => {
    switch (type) {
      case FormFieldType.TEXT:
        return <Input {...inputProps} />;
      case FormFieldType.PHONE_NUMBER:
        return <InputNumber {...inputProps} />;
      case FormFieldType.TEXT_AREA:
        return (
          <div className="relative">
            <TextArea {...inputProps} />
            {inputProps?.children}
          </div>
        );
      case FormFieldType.SELECT:
        return (
          <Select {...inputProps}>
            {options?.map((option, idx) => (
              <Select.Option key={idx.toString()} value={option.value}>
                {option.label}
              </Select.Option>
            ))}
          </Select>
        );
      case FormFieldType.FILE:
        return (
          <Upload {...inputProps}>
            {inputProps?.currentImage ? (
              <Image src={inputProps.currentImage} alt="avatar" />
            ) : (
              uploadButton
            )}
          </Upload>
        );
      case FormFieldType.RATE:
        return <Rate {...inputProps} />;
      default:
        return <Input {...inputProps} />;
    }
  };

  return (
    <Form.Item
      name={name}
      label={label}
      rules={rules}
      validateStatus={apiErrors?.[name as string] ? 'error' : undefined}
      help={apiErrors?.[name as string]}
      {...formItemProps}
    >
      {renderInput()}
    </Form.Item>
  );
};
