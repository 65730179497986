import { Col, Row } from 'antd';
import React from 'react';
import AuthCarousel from '../../modules/auth/AuthCarousel';

export default function AuthLayout({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <Row className="auth-layout" gutter={0}>
      <Col span={24} lg={12}>
        <AuthCarousel />
      </Col>
      <Col span={24} lg={12}>
        <div className="auth-container">{children}</div>
      </Col>
    </Row>
  );
}
