import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

type AddMenuItemType = {
  title: string;
  description: string;
  icon: React.ReactNode;
};

export default function AddMenuItem({
  title,
  description,
  icon,
}: AddMenuItemType) {
  return (
    <Flex gap={8} align="center">
      <Flex>{icon}</Flex>
      <Flex vertical gap={4}>
        <Paragraph className="mb-0">{title}</Paragraph>
        <Paragraph className="mb-0 text-meta text-content-secondary text-description">
          {description}
        </Paragraph>
      </Flex>
    </Flex>
  );
}
