export enum FormResultBy {
  Overview = 'overview',
  ByQuestion = 'by-question',
  ByParticipants = 'by-participants',
  PartiallyFilled = 'partially-filled',
}

export type DataType = {
  id: React.Key;
  contact: {
    fullName: string;
    phone: string;
    email: string;
    company: string;
  };
  number: number;
  experience: number;
  process: string;
  responsibilities: number;
  feedback: string;
  download: string;
};
