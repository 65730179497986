import { PencilSimple } from '@phosphor-icons/react';
import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

export default function CollapseHeader({
  title,
  description,
}: {
  title: string;
  description?: string;
}) {
  return (
    <Flex gap={8} vertical>
      <Flex gap={6} align="center">
        <PencilSimple weight="fill" size={18} color="var(--content-tertiary)" />
        <Paragraph className="text-content-tertiary semi-bold mb-0">
          {title}
        </Paragraph>
      </Flex>
      <Paragraph className="text-content-primary mb-0">{description}</Paragraph>
    </Flex>
  );
}
