import { Checkbox, CheckboxProps, ConfigProvider, ThemeConfig } from 'antd';

type BoxType = 'primary' | 'secondary';

export default function CommonCheckBox({
  children,
  boxType,
  ...restProps
}: CheckboxProps & { boxType: BoxType }) {
  const config: Record<string, ThemeConfig> = {
    primary: {
      token: { colorPrimary: '#343b3f' },
    },
    secondary: {
      token: { colorPrimary: '#F3F5F6' },
    },
  };

  return (
    <ConfigProvider theme={config?.[boxType]}>
      <Checkbox {...restProps} className={boxType}>
        {children}
      </Checkbox>
    </ConfigProvider>
  );
}
