import { FormThemeMode } from '../../__generated__/graphql';
import LogoDarkImg from '../../assets/logo/logo-dark.svg';
import LogoLightImg from '../../assets/logo/logo-light.svg';
export default function Logo({
  width = 140,
  mode,
}: {
  width?: number;
  mode: FormThemeMode;
}) {
  if (mode === FormThemeMode.Light) {
    return <img src={LogoDarkImg} alt="zinq logo" width={width} />;
  }
  return <img src={LogoLightImg} alt="zinq logo" width={width} />;
}
