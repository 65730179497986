import { useLazyQuery } from '@apollo/client';
import {
  Buildings,
  DownloadSimple,
  EnvelopeSimple,
  Export,
  Info,
  Paperclip,
  Phone,
} from '@phosphor-icons/react';
import type { TableColumnsType } from 'antd';
import { Flex, Rate, Table, Tag, Tooltip, Typography } from 'antd';
import dayjs from 'dayjs';
import {
  compact,
  concat,
  isArray,
  isEmpty,
  lowerCase,
  map,
  startCase,
  trim,
} from 'lodash';
import { useContext, useEffect, useRef, useState } from 'react';
import {
  FormSubmission,
  FormSubmissionType,
  FormSubmissions,
  ListFormSubmissionsSortOnField,
  SortOrder,
} from '../../__generated__/graphql';
import { AppContext } from '../../AppContext';
import api from '../../common/api';
import {
  DEFAULT_ITEMS_PER_PAGE,
  REST_URL,
  RESULT_DATE_FORMAT,
} from '../../common/constants';
import { getFileDownloadName } from '../../common/utils';
import TranscriptModal from '../../components/common/TranscriptModal';
import CommonButton from '../../components/primitives/CommonButton';
import CommonSwitch from '../../components/primitives/CommonSwitch';
import useQueryParams from '../../hooks/useQueryParams';
import { AppContextType } from '../../types/appContext.type';
import { FormFieldType } from '../auth/auth.types';
import { FormSchemaType } from '../form-builder/model/form/form.types';
import { GET_RESULT_BY_PARTICIPANTS } from '../form/graphql/queries';
import { DataType } from './result.types';
const { Paragraph } = Typography;

export default function ByParticipants() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentSubmissionId, setCurrentSubmissionId] = useState<string>('');
  const [metaData, setMetaData] = useState<FormSubmission>();
  const [isFetch, setIsFetch] = useState<boolean>(true);
  const [uploading, setUploading] = useState<boolean>(false);
  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;
  const [hasMore, setHasMore] = useState(false);
  const {
    state: { formSettings },
  } = useContext(AppContext) as AppContextType;

  const [initialFilter, setInitialFilter] = useState({
    filter: {
      isTest: false,
      isCompleted: true,
      limit: DEFAULT_ITEMS_PER_PAGE,
      skip: 0,
    },
    where: {
      formId,
    },
    sort: [
      {
        sortBy: SortOrder.Desc,
        sortOn: ListFormSubmissionsSortOnField.CreatedAt,
      },
    ],
  });

  const [fetchData, { loading }] = useLazyQuery(GET_RESULT_BY_PARTICIPANTS, {
    fetchPolicy: 'network-only',
  });

  const [dataSet, setDataSet] = useState<JSON[]>([]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [columnSet, setColumnSet] = useState<any>([]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getColumnBody = (value: any, formSchema: FormSchemaType) => {
    switch (formSchema?.answer_type) {
      case FormFieldType.DATE:
        return dayjs(value).format(RESULT_DATE_FORMAT);
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.LONG_TEXT:
      case FormFieldType.EMAIL:
      case FormFieldType.NUMBER:
      case FormFieldType.PHONE_NUMBER:
      case FormFieldType.TIME:
        return value;
      case FormFieldType.ADDRESS:
        // eslint-disable-next-line no-case-declarations
        const addressParts = [
          value?.address_line_1,
          value?.address_line_2,
          value?.city_town,
          value?.state_region_province,
          value?.zip_post_code,
          value?.country,
        ];
        return compact(addressParts)?.join(', ') || '-';
      case FormFieldType.CONTACT_INFO:
        // eslint-disable-next-line no-case-declarations
        const fullName = `${value?.first_name || ''} ${value?.last_name || ''}`;
        return (
          <div className="flex flex-column gap-6">
            <div className="text-sm">{trim(fullName) || '-'}</div>
            <div className="text-sm flex items-center gap-6 text-content-secondary">
              <Phone size={14} color="var(--content-primary)" weight="fill" />
              {value?.phone || '-'}
            </div>
            <div className="text-sm flex items-center gap-6 text-content-secondary">
              <EnvelopeSimple
                size={14}
                color="var(--content-primary)"
                weight="fill"
              />
              {value?.email || '-'}
            </div>
            <div className="text-sm flex items-center gap-6 text-content-secondary">
              <Buildings
                size={14}
                color="var(--content-primary)"
                weight="fill"
              />
              {value?.company_name || '-'}
            </div>
          </div>
        );
      case FormFieldType.FILE:
        return (
          <Flex gap={8} wrap>
            {isArray(value) &&
              map(value, (file, idx) => {
                return (
                  <a
                    href={file?.url}
                    target="_blank"
                    rel="noreferrer"
                    key={idx}
                  >
                    <Tag
                      bordered={false}
                      className="download-btn text-content-primary"
                    >
                      <Paperclip size={14} color="var(--content-primary)" />
                      {file?.fileName}
                      <DownloadSimple
                        size={14}
                        color="var(--content-primary)"
                      />
                    </Tag>
                  </a>
                );
              })}
          </Flex>
        );
      case FormFieldType.SELECT:
      case FormFieldType.MULTI_SELECT:
        return isArray(value) ? value?.join(', ') : value;
      case FormFieldType.LINEAR_SCALE:
        return (
          <Flex className="mb-0" align="flex-end">
            <Paragraph className="mb-0 text-content-primary text-base semi-bold">
              {value}
            </Paragraph>
            <Paragraph className="mb-0 text-content-secondary">{`/${formSchema.config?.max_rating}`}</Paragraph>
          </Flex>
        );
      case FormFieldType.RATE:
        return (
          <Rate
            disabled
            defaultValue={value}
            count={formSchema.config?.max_rating}
          />
        );
      default:
        return value;
    }
  };

  const generateTableHeaders = (items: FormSchemaType[]) => {
    // add default sr no. field
    const column: TableColumnsType<DataType> = [];

    // map form fields to get title & render component
    map(items, (val, index) => {
      if (index === 0) {
        column.push({
          title: 'Form Type',
          width: 100,
          dataIndex: 'type',
          key: 'type',
          render: (row) => {
            return startCase(lowerCase(row));
          },
        });
      }
      column.push({
        title: startCase(val.question_title) || '',
        width: 250,
        dataIndex: val?.answer_store_key,
        key: val?.answer_store_key as string,
        render: (row) => (row?.value ? getColumnBody(row?.value, val) : '-'),
      });
      if (index === items.length - 1) {
        column.push({
          title: 'Transcript',
          key: 'id',
          fixed: 'right',
          width: 150,
          render: (val) => (
            <a
              className="text-system-blue-dark"
              onClick={() => {
                setIsModalOpen(true);
                setMetaData(val);
                setIsFetch(
                  val?.type === FormSubmissionType.Voice ? false : true,
                );
              }}
            >
              <u>View Transcript</u>
            </a>
          ),
        });
      }
      // const obj = submissions?.find((obj) => obj?.key === val.answer_store_key);
      // if (!isEmpty()) {
      //   data.push(obj);
      // }
      // submissions.map((item) => {
      //   data.push(item?.submittedData);
      // });
    });

    return column;
  };

  const generateTableContent = (data: FormSubmissions) => {
    if (!isEmpty(data?.formStructure)) {
      const updatedData = generateTableHeaders(data?.formStructure);
      // set table header
      setColumnSet(updatedData);
      // set table body content
      setDataSet(
        data?.submissions?.map((item, idx) => {
          return {
            ...item?.submittedData,
            createdAt: item?.createdAt,
            idx: idx + 1,
            id: item?.id,
            type: item?.type,
            voiceConversation: item?.voiceConversation,
          };
        }) || [],
      );
    } else {
      setColumnSet([]);
      setDataSet([]);
    }
  };

  const observer = useRef<IntersectionObserver | null>(null);

  const loadMore = () => {
    fetchData({
      variables: {
        filter: {
          isTest: false,
          isCompleted: true,
          limit: DEFAULT_ITEMS_PER_PAGE,
          skip: dataSet.length,
        },
        where: {
          formId,
        },
        sort: [
          {
            sortBy: SortOrder.Desc,
            sortOn: ListFormSubmissionsSortOnField.CreatedAt,
          },
        ],
      },
      onCompleted: (res) => {
        const newData = res.formSubmissions?.data?.submissions?.map((item) => {
          return {
            ...item?.submittedData,
            id: item?.id,
          };
        });
        const mergedData = concat(dataSet, newData);
        setDataSet(mergedData);
        setHasMore(Number(res.formSubmissions?.count) > mergedData.length);
      },
    });
  };

  const handleCsvExport = async () => {
    setUploading(true);
    try {
      const response = await api(
        `${REST_URL.exportByParticipants}/${formId}?isTest=${initialFilter.filter.isTest}`,
      );
      const csvData = response.data;
      const blob = new Blob([csvData], { type: 'text/csv' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        getFileDownloadName(formSettings?.title || ''),
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      return e;
    } finally {
      setUploading(false);
    }
  };

  useEffect(() => {
    fetchData({
      variables: initialFilter,
      onCompleted: (res) => {
        generateTableContent(res.formSubmissions?.data as FormSubmissions);
        setHasMore(Number(res.formSubmissions?.count) > DEFAULT_ITEMS_PER_PAGE);
      },
    });
  }, [initialFilter]);

  return (
    <div className="participant-wrapper">
      <div className="participant-filter-top">
        <div className="flex item-center gap-4">
          <span className="body-sm flex items-center gap-4 text-content-primary medium">
            Show preview responses
            <Tooltip title="View responses filled in preview">
              <Info
                size={20}
                color="var(--content-primary)"
                className="cursor-pointer"
              />
            </Tooltip>
            :
          </span>
          <CommonSwitch
            type="primary"
            value={initialFilter.filter.isTest}
            onChange={(checked) => {
              setInitialFilter({
                ...initialFilter,
                filter: {
                  ...initialFilter.filter,
                  isTest: checked,
                },
              });
            }}
          />
        </div>
        <CommonButton
          type="default"
          onClick={handleCsvExport}
          icon={<Export color="var(--content-primary)" size={14} />}
          loading={uploading}
          disabled={loading}
        >
          Export
        </CommonButton>
      </div>
      <div className="participant-tbl">
        <Table
          columns={[
            {
              title: 'Sr no.',
              dataIndex: 'index',
              width: 48,
              key: 'number',
              fixed: 'left',
              render: (text, record, index) => index + 1,
            },
            ...columnSet,
          ]}
          bordered
          pagination={false}
          loading={loading}
          dataSource={dataSet}
          // onScroll={handleTableScroll}
          footer={() =>
            hasMore ? (
              <Flex justify="center">
                <CommonButton
                  loading={loading}
                  type="primary"
                  size="small"
                  onClick={() => {
                    loadMore();
                  }}
                >
                  Load More
                </CommonButton>
              </Flex>
            ) : null
          }
          scroll={
            hasMore
              ? { x: 1300, y: 'calc(100vh - 332px)' }
              : { x: 1300, y: 'calc(100vh - 292px)' }
          }
        />
        {/* {hasMore && (
          <div ref={lastItemRef} style={{ height: '20px', margin: '10px 0' }}>
            {loading && <Spin />}
          </div>
        )} */}
        {/* Transcript Modal */}
        {!isEmpty(metaData) && (
          <TranscriptModal
            open={isModalOpen}
            footer={false}
            width={700}
            isFetch={isFetch}
            onOk={() => {
              setIsModalOpen(false);
            }}
            metaData={metaData}
            onCancel={() => {
              setIsModalOpen(false);
            }}
          />
        )}
      </div>
    </div>
  );
}
