import { Microphone } from '@phosphor-icons/react';
import { Col, ConfigProvider, Divider, Flex, Form, Row } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { CreateFormMutation } from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import { formValidation } from '../../common/utils';
import CommonButton from '../../components/primitives/CommonButton';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { FormFieldType } from '../auth/auth.types';
import { FormField } from '../form/FormField';
import { CREATE_FORM } from '../form/graphql/mutations';
import { useForm } from '../form/hooks/useForm';

export default function PromptScreen() {
  const { navigate } = useRouter();
  const { form, loading, apiErrors, handleSubmit } = useForm(CREATE_FORM, {
    onCompleted: (res: CreateFormMutation) => {
      navigate(`${ROUTES.EDITOR}?id=${res.createForm?.data?.id}`);
    },
  });
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const ExampleCard = ({ description }: { description: string }) => {
    return (
      <Col lg={8}>
        <div className="card-box">
          <Paragraph className="card-heading">{'// EXAMPLE'}</Paragraph>
          <Divider className="mt-8 mb-8" />
          <Paragraph className="text-content-secondary" ellipsis={{ rows: 5 }}>
            {description}
          </Paragraph>
        </div>
      </Col>
    );
  };

  const handleKeyDown = async (event: KeyboardEvent) => {
    // Check if the Enter key is pressed and no modifier keys are held down
    if (
      event.key === 'Enter' &&
      !event.shiftKey &&
      !event.ctrlKey &&
      !event.altKey
    ) {
      event.preventDefault();
      try {
        await form.validateFields();
        form.submit();
      } catch (e) {
        return e;
      }
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Input: {
            colorBorder: '#E5E8E9',
            borderRadius: 16,
            lineWidth: 1,
            fontSize: 16,
            colorText: 'var(--content-primary)',
          },
        },
      }}
    >
      <div className="onboarding-wrapper">
        <Flex vertical gap={8}>
          <Title className="font-secondary text-center mb-8 semi-bold">
            Create with AI
          </Title>
          <Flex vertical>
            <Paragraph className="text-description text-center text-content-secondary">
              Just type your request clearly. Be specific for best results.
            </Paragraph>
            <Paragraph className="text-description text-center text-content-secondary">
              Let AI handle the rest!
            </Paragraph>
          </Flex>
        </Flex>
        <section className="container">
          <Form
            form={form}
            className="prompt-form"
            onFinish={(formValues) =>
              handleSubmit({
                data: {
                  ...formValues,
                },
              })
            }
          >
            <div className="information-wrapper">
              <FormField
                name="prompt"
                type={FormFieldType.TEXT_AREA}
                apiErrors={apiErrors}
                rules={[formValidation.required]}
                inputProps={{
                  placeholder: 'What information do you want to collect?',
                  maxLength: 500,
                  autoSize: { minRows: 3 },
                  className: 'input-prompt',
                  onKeyDown: handleKeyDown,
                  children: (
                    <div className="float-microphone">
                      <Microphone
                        size={20}
                        weight="fill"
                        color="var(--content-tertiary)"
                      />
                    </div>
                  ),
                }}
              />
              <Flex justify="center">
                <CommonButton
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  size="large"
                >
                  Submit
                </CommonButton>
              </Flex>
            </div>
          </Form>
        </section>
        <section className="container">
          <Row className="example-list" gutter={[16, 16]}>
            <ExampleCard description="Feedback form for my party with up to 5 questions and a photo upload option." />
            <ExampleCard description="Check-in form for your health and wellness program." />
            <ExampleCard description="Survey to measure customer satisfaction with our services." />
          </Row>
        </section>
      </div>
    </ConfigProvider>
  );
}
