import {
  Buildings,
  DownloadSimple,
  EnvelopeSimple,
  Info,
  Paperclip,
  Phone,
} from '@phosphor-icons/react';
import type { TableColumnsType } from 'antd';
import { Rate, Table, Typography } from 'antd';
import CommonSwitch from '../../components/primitives/CommonSwitch';
const { Paragraph } = Typography;
type DataType = {
  id: React.Key;
  contact: {
    fullName: string;
    phone: string;
    email: string;
    company: string;
  };
  number: number;
  experience: number;
  process: string;
  responsibilities: number;
  feedback: string;
  download: string;
};

const columns: TableColumnsType<DataType> = [
  {
    title: 'Sr no.',
    width: 48,
    dataIndex: 'number',
    key: 'number',
  },
  {
    title: 'Contact',
    width: 250,
    dataIndex: 'contact',
    key: 'contact',
    render: (value: DataType['contact']) => {
      return (
        <div className="flex flex-column gap-6">
          <div className="text-sm"> {value.fullName}</div>
          <div className="text-sm flex items-center gap-6 text-content-secondary">
            <Phone size={14} color="var(--content-primary)" weight="fill" />{' '}
            {value.phone}
          </div>
          <div className="text-sm flex items-center gap-6 text-content-secondary">
            <EnvelopeSimple
              size={14}
              color="var(--content-primary)"
              weight="fill"
            />
            {value.email}
          </div>
          <div className="text-sm flex items-center gap-6 text-content-secondary">
            <Buildings size={14} color="var(--content-primary)" weight="fill" />
            {value.company}
          </div>
        </div>
      );
    },
  },
  {
    title: 'Onboarding experience',
    dataIndex: 'experience',
    width: 200,
    key: 'experience',
    render: (value) => <Rate disabled defaultValue={value} />,
  },
  {
    title: 'Liked part of process',
    width: 200,
    dataIndex: 'process',
    key: 'process',
  },
  {
    title: 'Clarity of job responsibilities',
    width: 200,
    dataIndex: 'responsibilities',
    key: 'responsibilities',
    render: (value) => <Rate disabled defaultValue={value} />,
  },
  {
    title: 'Feedback',
    width: 200,
    dataIndex: 'feedback',
    key: 'feedback',
    render: (value) => (
      <Paragraph
        className="text-sm text-content-primary"
        ellipsis={{ rows: 4 }}
      >
        {value}
      </Paragraph>
    ),
  },
  {
    title: 'Photo for your employee ID badge',
    width: 200,
    dataIndex: 'download',
    key: 'download',
    render: (value) => (
      <div className="text-sm download-btn text-content-primary">
        <Paperclip size={14} color="var(--content-primary)" />
        {value}
        <DownloadSimple size={14} color="var(--content-primary)" />
      </div>
    ),
  },
  {
    title: 'Transcript',
    key: 'operation',
    fixed: 'right',
    width: 150,
    render: () => <a>View Transcript</a>,
  },
];

const data: DataType[] = [
  {
    id: '1',
    contact: {
      fullName: 'Aakash Rathod ',
      phone: '+91 1234567890',
      email: 'aakash.rathod@gmail.com',
      company: 'Logicwind',
    },
    number: 1,
    experience: 4,
    process: 'Company culture Intro',
    responsibilities: 5,
    feedback:
      'The process was smooth, but I would have appreciated more detailed information on HR policies.',
    download: 'file.png',
  },
  {
    id: '2',
    contact: {
      fullName: 'Aakash Rathod ',
      phone: '+91 1234567890',
      email: 'aakash.rathod@gmail.com',
      company: 'Logicwind',
    },
    number: 2,
    experience: 4,
    process: 'Company culture Intro',
    responsibilities: 5,
    feedback:
      'The process was smooth, but I would have appreciated more detailed information on HR policies.',
    download: 'file.png',
  },
  {
    id: '3',
    contact: {
      fullName: 'Aakash Rathod ',
      phone: '+91 1234567890',
      email: 'aakash.rathod@gmail.com',
      company: 'Logicwind',
    },
    number: 3,
    experience: 4,
    process: 'Company culture Intro',
    responsibilities: 5,
    feedback:
      'The process was smooth, but I would have appreciated more detailed information on HR policies.',
    download: 'file.png',
  },
  {
    id: '4',
    contact: {
      fullName: 'Aakash Rathod ',
      phone: '+91 1234567890',
      email: 'aakash.rathod@gmail.com',
      company: 'Logicwind',
    },
    number: 4,
    experience: 4,
    process: 'Company culture Intro',
    responsibilities: 5,
    feedback:
      'The process was smooth, but I would have appreciated more detailed information on HR policies.',
    download: 'file.png',
  },
  {
    id: '5',
    contact: {
      fullName: 'Aakash Rathod ',
      phone: '+91 1234567890',
      email: 'aakash.rathod@gmail.com',
      company: 'Logicwind',
    },
    number: 5,
    experience: 4,
    process: 'Company culture Intro',
    responsibilities: 5,
    feedback:
      'The process was smooth, but I would have appreciated more detailed information on HR policies.',
    download: 'file.png',
  },
  {
    id: '6',
    contact: {
      fullName: 'Aakash Rathod ',
      phone: '+91 1234567890',
      email: 'aakash.rathod@gmail.com',
      company: 'Logicwind',
    },
    number: 6,
    experience: 4,
    process: 'Company culture Intro',
    responsibilities: 5,
    feedback:
      'The process was smooth, but I would have appreciated more detailed information on HR policies.',
    download: 'file.png',
  },
];

export default function Settings() {
  return (
    <div className="participant-wrapper">
      <div className="participant-filter-top">
        <div className="flex item-center gap-4">
          <span className="body-sm flex item-center gap-4 text-content-primary">
            Show preview responses
            <Info size={20} color="var(--content-primary)" /> :
          </span>
          <CommonSwitch type="primary" />
        </div>
      </div>
      <div className="participant-tbl">
        <Table
          columns={columns}
          bordered
          pagination={false}
          dataSource={data}
          scroll={{ x: 1300, y: 'calc(100vh - 280px)' }}
        />
      </div>
    </div>
  );
}
