import { CaretDown, CaretLeft, Plus, UserCircle } from '@phosphor-icons/react';
import { Avatar, Dropdown, Flex, MenuProps, Space } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { includes } from 'lodash';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { ROUTES } from '../../common/constants';
import { getInitials } from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

export default function AppHeader() {
  const {
    state: { currentUser },
  } = useContext(AppContext) as AppContextType;

  const {
    location: { pathname },
  } = useRouter();

  const workspaceItems: MenuProps['items'] = [
    {
      label: <Paragraph className="mb-0">Create New</Paragraph>,
      key: 'DUPLICATE',
      icon: <Plus size={16} color="var(--content-primary)" weight="regular" />,
      disabled: true,
    },
  ];

  const items: MenuProps['items'] = [
    {
      label: <Link to={ROUTES.LOGOUT}>Logout</Link>,
      key: '0',
    },
  ];

  const hiddenRoutesForProfile = [ROUTES.FORM_CREATE, ROUTES.FORM_TEMPLATES];

  return (
    <div className="app-header">
      <Space>
        {pathname !== ROUTES.MAIN ? (
          <Link to={ROUTES.MAIN}>
            <Flex align="center" gap={8}>
              <CaretLeft size={16} color="var(--content-tertiary)" />
              <Paragraph className="mb-0 text-content-tertiary">Back</Paragraph>
            </Flex>
          </Link>
        ) : currentUser?.defaultWorkspace?.id ? (
          <Dropdown menu={{ items: workspaceItems }} trigger={['click']}>
            <Flex align="center" gap={8}>
              <Paragraph className="mb-0 text-m semi-bold text-content-secondary">
                {currentUser?.defaultWorkspace?.name}
              </Paragraph>
              <CaretDown size={20} color="var(--content-secondary)" />
            </Flex>
          </Dropdown>
        ) : null}
      </Space>
      <Flex align="center" justify="space-between" gap={12}>
        {!includes(hiddenRoutesForProfile, pathname) && (
          <>
            <CommonButton
              icon={
                <UserCircle
                  size={14}
                  color="var(--content-primary)"
                  weight="fill"
                />
              }
              premium
              type="text"
            >
              Invite
            </CommonButton>
            <Dropdown menu={{ items }} trigger={['click']}>
              <a onClick={(e) => e.preventDefault()}>
                <Space>
                  {currentUser?.profileImage ? (
                    <Avatar
                      size="large"
                      src={<img src={currentUser?.profileImage} alt="avatar" />}
                    />
                  ) : (
                    currentUser?.name && (
                      <Avatar size="large">
                        {getInitials(currentUser?.name as string)}
                      </Avatar>
                    )
                  )}
                </Space>
              </a>
            </Dropdown>
          </>
        )}
      </Flex>
    </div>
  );
}
