import { Progress } from 'antd';
import { FormThemeMode } from '../../__generated__/graphql';
import Logo from './Logo';

const LoaderComponent = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center">
      <div className="flex flex-column items-center gap-12">
        <Logo width={120} mode={FormThemeMode.Light} />
        <div className="w-full">
          <Progress percent={60} size="small" showInfo={false} />

          {/* <Progress value={70} className="animate-pulse h-2.5" /> */}
        </div>
      </div>
    </div>
  );
};
export default LoaderComponent;
