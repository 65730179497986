export class PhoneNumber {
  constructor(
    // eslint-disable-next-line no-unused-vars
    public countryCode: string,
    // eslint-disable-next-line no-unused-vars
    public number: string,
  ) {}

  toString(): string {
    return `+${this.countryCode} ${this.number}`;
  }

  static fromString(value: string): PhoneNumber {
    const [countryCode, ...rest] = value.split(' ');
    return new PhoneNumber(countryCode.replace('+', ''), rest.join(' '));
  }
}
