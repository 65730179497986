import { Result } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import CommonButton from '../primitives/CommonButton';

const Error404 = () => (
  <Result
    status="404"
    title="404"
    subTitle="Sorry, the page you visited does not exist."
    extra={
      <Link type="primary" to={ROUTES.MAIN} replace>
        <CommonButton type="primary">Back Home</CommonButton>
      </Link>
    }
  />
);

export default Error404;
