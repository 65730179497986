import { ThemeConfig } from 'antd';

const classicFormTheme: ThemeConfig = {
  token: { boxShadow: 'unset' },
  components: {
    Input: {
      lineWidth: 0,
      borderRadius: 0,
      colorBgContainer: 'transparent',
      colorText: 'var(--neutrals)',
      hoverBorderColor: '#ff0000',
      activeBorderColor: 'transparent',
      colorError: 'var(--neutrals)',
      colorErrorText: 'var(--neutrals)',
      colorTextPlaceholder: 'var(--neutrals5)',
      boxShadow: 'none',
    },
    Checkbox: {
      paddingContentVertical: 16,
    },
    InputNumber: {
      lineWidth: 0,
      borderRadius: 0,
      colorBgContainer: 'transparent',
      colorText: 'var(--neutrals)',
      hoverBorderColor: '#ff0000',
      activeBorderColor: 'transparent',
      colorError: 'var(--neutrals)',
      colorErrorText: 'var(--neutrals)',
      colorTextPlaceholder: 'var(--neutrals5)',
      boxShadow: 'none',
    },
    Select: {
      lineWidth: 0,
      borderRadius: 0,
      colorPrimary: 'var(--dark-blue)',
      colorBgContainer: 'transparent',
      colorText: 'var(--neutrals11)',
      colorError: 'var(--neutrals)',
      colorErrorText: 'var(--red)',
      colorTextPlaceholder: 'var(--neutrals5)',
      optionSelectedColor: 'var(--neutrals11)',
      optionSelectedBg: 'var(--neutrals2)',
    },
    DatePicker: {
      colorBgContainer: 'transparent',
      hoverBorderColor: 'var(--neutrals)',
      colorPrimary: 'var(--dark-blue)',
      colorTextDescription: 'var(--neutrals)',
      colorTextPlaceholder: 'var(--neutrals)',
    },
    Form: {
      colorError: 'var(--red)',
      labelFontSize: 18,
      labelColor: 'var(--neutrals)',
      labelHeight: 32,
    },
    Upload: {
      colorText: 'var(--neutrals)',
      colorPrimary: 'var(--neutrals)',
    },
    Slider: {
      railBg: 'var(--neutrals3)',
      railHoverBg: 'var(--neutrals3)',
    },
    Button: {
      borderRadius: 50,
      colorPrimary: 'var(--neutrals)',
      primaryColor: 'var(--dark-blue)',
      colorPrimaryHover: 'var(--neutrals1)',
      defaultHoverColor: 'var(--neutrals3)',
      defaultHoverBorderColor: 'var(--neutrals3)',
    },
  },
};

export default classicFormTheme;
