import { App } from 'antd';
import { MessageInstance } from 'antd/es/message/interface';
import { HookAPI } from 'antd/es/modal/useModal';
import { NotificationInstance } from 'antd/es/notification/interface';

let messageContext: MessageInstance;
let modalContext: HookAPI;
let notificationContext: NotificationInstance;

export default () => {
  const { message, modal, notification } = App?.useApp();
  messageContext = message;
  modalContext = modal;
  notificationContext = notification;
  return null;
};

export { messageContext, modalContext, notificationContext };
