import { DotsSixVertical } from '@phosphor-icons/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';

type ResizableLayoutProps = {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
  minPercentage?: number;
  maxPercentage?: number;
};

const ResizableLayout: React.FC<ResizableLayoutProps> = ({
  leftContent,
  rightContent,
  minPercentage = 20,
  maxPercentage = 80,
}) => {
  const [leftPercentage, setLeftPercentage] = useState(40);
  const containerRef = useRef<HTMLDivElement>(null);
  const resizeHandleRef = useRef<HTMLDivElement>(null);

  const handleResize = useCallback(
    (mouseMoveEvent: MouseEvent) => {
      if (containerRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        const newLeftWidth = mouseMoveEvent.clientX - containerRect.left;
        const newLeftPercentage = (newLeftWidth / containerRect.width) * 100;
        setLeftPercentage(() =>
          Math.min(Math.max(newLeftPercentage, minPercentage), maxPercentage),
        );
      }
    },
    [minPercentage, maxPercentage],
  );

  const stopResize = useCallback(() => {
    window.removeEventListener('mousemove', handleResize);
    window.removeEventListener('mouseup', stopResize);
  }, [handleResize]);

  const startResize = useCallback(() => {
    window.addEventListener('mousemove', handleResize);
    window.addEventListener('mouseup', stopResize);
  }, [handleResize, stopResize]);

  useEffect(() => {
    const resizeHandle = resizeHandleRef.current;
    if (resizeHandle) {
      resizeHandle.addEventListener('mousedown', startResize);
    }

    return () => {
      if (resizeHandle) {
        resizeHandle.removeEventListener('mousedown', startResize);
      }
    };
  }, [startResize]);

  return (
    <div className="resizable-layout" ref={containerRef}>
      <div style={{ width: `${leftPercentage}%` }} className="left-container">
        {leftContent}
      </div>
      <div ref={resizeHandleRef} className="middle-container">
        <div className="middle-line">
          <DotsSixVertical size={16} color="var(--content-tertiary)" />
        </div>
      </div>
      <div
        style={{ width: `${100 - leftPercentage}%` }}
        className="right-container"
      >
        {rightContent}
      </div>
    </div>
  );
};

export default ResizableLayout;
