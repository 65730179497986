import { ValidationRule, ValidationRules } from '../ValidationRules';
import { FormField } from './FormField';

export class NumberField extends FormField {
  constructor(
    id: string,
    label: string,
    placeholder: string,
    required: boolean,
    public min?: number,
    public max?: number,
    validationRules: ValidationRule[] = [],
  ) {
    super(id, 'number', label, required, placeholder, [
      ValidationRules.number,
      ...validationRules,
    ]);

    if (min !== undefined) {
      this.validationRules.push(ValidationRules.min(min));
    }
    if (max !== undefined) {
      this.validationRules.push(ValidationRules.max(max));
    }
  }

  validate(value: string): string | null {
    const numValue = parseFloat(value);
    for (const rule of this.validationRules) {
      const error = rule(numValue);
      if (error) return error;
    }
    return null;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      min: this.min,
      max: this.max,
    };
  }
}
