import { ValidationRule } from '../ValidationRules';
import { ContactInfo } from './ContactInfo';
import { EmailField } from './EmailField';
import { FormField } from './FormField';
import { PhoneField } from './PhoneField';
import { TextField } from './TextField';

export class ContactInfoField extends FormField {
  public firstName: TextField;
  public lastName: TextField;
  public phoneNumber: PhoneField;
  public email: EmailField;
  public companyName: TextField;

  constructor(
    id: string,
    label: string,
    required: boolean,
    placeholder: string,
    public countryCodeOptions: { value: string; label: string }[],
    validationRules: ValidationRule[] = [],
  ) {
    super(id, 'contactInfo', label, required, '', validationRules);

    this.firstName = new TextField(
      `a2623511-c46d-4e54-90fb-0de98ab2f1d4`,
      'First Name',
      required,
      'Enter your first name',
    );
    this.lastName = new TextField(
      `a2623511-c46d-4e54-90fb-0de98ab2f1d5`,
      'Last Name',
      required,
      'Enter your last name',
    );
    this.phoneNumber = new PhoneField(
      'a2623511-c46d-4e54-90fb-0de98ab2f1d6',
      'Phone Number',
      'Enter phone number',
      required,
      countryCodeOptions,
    );
    this.email = new EmailField(
      `email`,
      'Email',
      required,
      'Enter your email address',
    );
    this.companyName = new TextField(
      `a2623511-c46d-4e54-90fb-0de98ab2f1d9`,
      'Company Name',
      required,
      'Enter company name',
    );
  }

  validate(value: ContactInfo): string | null {
    const errors: string[] = [];

    const firstNameError = this.firstName.validate(value.firstName);
    if (firstNameError) errors.push(firstNameError);

    const lastNameError = this.lastName.validate(value.lastName);
    if (lastNameError) errors.push(lastNameError);

    const phoneNumberError = this.phoneNumber.validate(value.phoneNumber);
    if (phoneNumberError) errors.push(phoneNumberError);

    const emailError = this.email.validate(value.email);
    if (emailError) errors.push(emailError);

    const companyNameError = this.companyName.validate(value.companyName);
    if (companyNameError) errors.push(companyNameError);

    for (const rule of this.validationRules) {
      const error = rule(value);
      if (error) errors.push(error);
    }

    return errors.length > 0 ? errors.join(', ') : null;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      firstName: this.firstName.toJSON(),
      lastName: this.lastName.toJSON(),
      phoneNumber: this.phoneNumber.toJSON(),
      email: this.email.toJSON(),
      companyName: this.companyName.toJSON(),
    };
  }
}
