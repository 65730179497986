import { PhoneNumber } from './PhoneNumber';

export class ContactInfo {
  constructor(
    // eslint-disable-next-line no-unused-vars
    public firstName: string,
    // eslint-disable-next-line no-unused-vars
    public lastName: string,
    // eslint-disable-next-line no-unused-vars
    public phoneNumber: PhoneNumber,
    // eslint-disable-next-line no-unused-vars
    public email: string,
    // eslint-disable-next-line no-unused-vars
    public companyName: string,
  ) {}

  static empty(): ContactInfo {
    return new ContactInfo('', '', new PhoneNumber('', ''), '', '');
  }
}
