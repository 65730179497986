import { Layout } from 'antd';
import { Outlet } from 'react-router-dom';
import { ChildrenType } from '../../types/common.type';

const { Content } = Layout;

const ScreenLayout = ({ children }: ChildrenType) => {
  return (
    <Layout>
      <Layout className="screen-layout">
        <Content>
          <Outlet />
          {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default ScreenLayout;
