import { useLazyQuery } from '@apollo/client';
import { CaretLeft, UploadSimple } from '@phosphor-icons/react';
import {
  ConfigProvider,
  Flex,
  Form,
  Input,
  Upload,
  UploadFile,
  UploadProps,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useEffect, useState } from 'react';
import { ALLOWED_FILE_TYPES, ALLOWED_SIZE } from '../../../../common/constants';
import {
  commonUploadFile,
  validatePageBackgroundImage,
} from '../../../../common/utils';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import useQueryParams from '../../../../hooks/useQueryParams';
import { FormFieldType } from '../../../auth/auth.types';
import {
  InputChangeTypes,
  PageLayoutType,
} from '../../../form-builder/model/form/form.types';
import { GET_PAGE_BG_URL, GET_SIGNED_URL_PAGES } from '../../graphql/queries';
import PageLayoutOption from '../utils/PageLayoutOption';
import { CommonPageTypes } from './pages.types';

export const EditEndPage: React.FC<
  CommonPageTypes & {
    onChange: ({ id, path, value }: InputChangeTypes) => void;
  }
> = ({ onBack, item, onChange }) => {
  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const [getSignedUrlMutate, { loading }] = useLazyQuery(GET_SIGNED_URL_PAGES, {
    fetchPolicy: 'network-only',
    variables: {
      data: {
        formId: formId as string,
        pageType: FormFieldType.WELCOME_PAGE,
        fileName: '',
      },
    },
    onError: () => {},
  });

  const [getImageURL] = useLazyQuery(GET_PAGE_BG_URL, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  useEffect(() => {
    if (item?.bg_image_url) {
      setFileList([
        {
          url: item.bg_image_url,
          uid: '-1',
          name: item?.bg_file_name as string,
          status: 'done',
        },
      ]);
    }
  }, []);

  const handleUpload =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    async ({ file, onSuccess, onError }: any) => {
      const validateMessage = validatePageBackgroundImage(
        file,
        ALLOWED_SIZE.pageBgImage,
        ALLOWED_FILE_TYPES.pageBgImage,
      );

      if (validateMessage) {
        onError(new Error(validateMessage));
        return;
      }

      getSignedUrlMutate({
        variables: {
          data: {
            formId: formId as string,
            pageType: FormFieldType.END_PAGE,
            fileName: file?.name,
          },
        },
        onCompleted: async (res) => {
          const signedUrl = res.getFormPageImageUploadSignedUrl?.signedUrl;
          const key = res.getFormPageImageUploadSignedUrl?.key;
          if (signedUrl && key) {
            const success = await commonUploadFile(signedUrl, file);
            if (success) {
              getImageURL({
                variables: {
                  data: {
                    imageKey: key,
                  },
                },
                onCompleted: (res) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['bg_image', 'bg_image_url', 'bg_file_name'],
                    value: [
                      key as string,
                      res.getFormStructurePagesImageUrl?.imageUrl as string,
                      file.name,
                    ],
                  });
                  onSuccess(null, file);
                },
              });
            } else {
              onError(new Error('Upload failed'));
            }
          }
        },
      });
    };

  // upload props for welcome page
  const uploadProps: UploadProps = {
    accept: ALLOWED_FILE_TYPES.pageBgImage,
    name: 'file',
    multiple: false,
    fileList,
    customRequest: handleUpload,
    onRemove: () => {
      onChange({
        id: item?.question_id || '',
        path: ['bg_image_url', 'bg_image'],
        value: ['', ''],
      });
    },
    onChange: (info: Parameters<Required<UploadProps>['onChange']>[0]) => {
      setFileList(info.fileList);
    },
  };
  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
            colorLink: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
          <CommonButton
            type="text"
            shape="circle"
            onClick={() => onBack()}
            shadow={false}
            icon={<CaretLeft color="var(--content-primary)" size={20} />}
          />
          <Paragraph className="mb-0 text-content-primary text-base semi-bold">
            End Page
          </Paragraph>
        </Flex>
        <div className="scroll-wrapper-body">
          <Flex
            gap={16}
            wrap
            justify="space-between"
            align="flex-start"
            className="mb-24"
          >
            <Flex vertical>
              <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                Display end page
              </Paragraph>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Page will displayed to user submits a form.
              </Paragraph>
            </Flex>
            <CommonSwitch
              type="primary"
              checked={item?.is_display}
              onChange={(checked) =>
                onChange({
                  id: item?.question_id || '',
                  path: ['is_display'],
                  value: [checked],
                })
              }
            />
          </Flex>
          <Flex vertical gap={20}>
            <Form.Item label="Page Layout" labelCol={{ span: 24 }}>
              <Flex gap={12}>
                <PageLayoutOption
                  value={item?.page_layout as PageLayoutType}
                  onChange={(val) => {
                    onChange({
                      id: item?.question_id || '',
                      path: ['page_layout'],
                      value: [val],
                    });
                  }}
                />
              </Flex>
            </Form.Item>
            <Form.Item label="Image" labelCol={{ span: 24 }}>
              <Upload {...uploadProps}>
                {fileList.length >= 1 ? null : (
                  <CommonButton
                    type="text"
                    icon={<UploadSimple />}
                    disabled={loading}
                  >
                    Upload
                  </CommonButton>
                )}
              </Upload>
            </Form.Item>
            <Form.Item label="Title" labelCol={{ span: 24 }}>
              <Input
                placeholder="Enter title here..."
                value={item?.title}
                onChange={(e) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['title'],
                    value: [e.target.value],
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="Body" labelCol={{ span: 24 }}>
              <Input.TextArea
                autoSize={{ minRows: 3 }}
                placeholder="Enter body here..."
                value={item?.body}
                onChange={(e) => {
                  onChange({
                    id: item?.question_id || '',
                    path: ['body'],
                    value: [e.target.value],
                  });
                }}
              />
            </Form.Item>
            <Flex vertical>
              <Flex align="center" gap={8}>
                <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                  CTA
                </Paragraph>
                <PremiumLogo />
              </Flex>
              <Paragraph className="mb-0 text-content-tertiary medium">
                Customize CTA on end page
              </Paragraph>
            </Flex>
            <Flex vertical gap={16}>
              <Form.Item label="Button text" labelCol={{ span: 24 }}>
                <Input
                  disabled
                  placeholder="Enter CTA text here..."
                  value={item?.cta_name}
                  onChange={(e) => {
                    onChange({
                      id: item?.question_id || '',
                      path: ['cta_name'],
                      value: [e.target.value],
                    });
                  }}
                />
              </Form.Item>
              <Form.Item
                label="Button redirection link"
                labelCol={{ span: 24 }}
              >
                <Input
                  placeholder="Enter link here..."
                  disabled
                  value={item?.cta_link}
                  onChange={(e) => {
                    onChange({
                      id: item?.question_id || '',
                      path: ['cta_link'],
                      value: [e.target.value],
                    });
                  }}
                />
              </Form.Item>
            </Flex>
          </Flex>
        </div>
      </ConfigProvider>
    </section>
  );
};
