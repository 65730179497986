import { useMutation } from '@apollo/client';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { User } from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import LoaderComponent from '../../components/common/LoaderComponent';
import useRouter from '../../hooks/useRouter';
import { AppContextType } from '../../types/appContext.type';
import { REFRESH_TOKEN } from './graphql/mutations';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken } = useContext(
    AppContext,
  ) as AppContextType;
  const { navigate } = useRouter();
  const refreshToken = getRefreshToken();
  function successCallback(accessToken: string | null, userData: User | null) {
    initializeAuth(accessToken, userData);
  }

  const [refresh, { loading }] = useMutation(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    onCompleted: (response) => {
      const accessToken = response?.refreshToken?.accessToken ?? null;
      const userData = response?.refreshToken?.user ?? null;
      successCallback(accessToken, userData);
      navigate(ROUTES?.MAIN);
    },
    onError: () => {
      navigate(ROUTES?.LOGOUT, { replace: true });
    },
  });

  useEffect(() => {
    if (refreshToken)
      refresh({
        variables: {
          data: {
            refreshToken,
          },
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
