import { ApolloError, DocumentNode, useMutation } from '@apollo/client';
import { Form } from 'antd';
import { GraphQLError } from 'graphql';
import { useState } from 'react';
import { TOAST_MESSAGE } from '../../../common/constants';
import { messageContext } from '../../../components/context/AppContextHolder';

// Custom hook for form management with GraphQL mutations
export const useForm = (
  mutation?: DocumentNode,
  mutationOptions?: {
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    onCompleted?: (data: any) => void; // data can be of any type
    // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
    onError?: (error: any) => void; // error can be of any type
  },
) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState<Record<string, string>>({});

  const handleGraphQLErrors = (error: ApolloError) => {
    if (error.graphQLErrors) {
      const errors: Record<string, string> = {};
      error.graphQLErrors.forEach((gqlError: GraphQLError) => {
        const extensions = gqlError.extensions as
          | { field?: string }
          | undefined;
        if (extensions && extensions?.field) {
          errors[extensions.field] = gqlError.message;
        }
      });
      setApiErrors(errors);
    } else {
      messageContext.error(TOAST_MESSAGE.somethingWentWrong);
    }
  };

  const [executeMutation] = useMutation(mutation as DocumentNode, {
    onCompleted: (data) => {
      setLoading(false);
      if (mutationOptions?.onCompleted) {
        mutationOptions.onCompleted(data);
      }
    },
    onError: (error) => {
      setLoading(false);
      handleGraphQLErrors(error);
      if (mutationOptions?.onError) {
        mutationOptions.onError(error);
      }
    },
    fetchPolicy: 'no-cache',
  });

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = async (values: any) => {
    // dynamic form items can be of any type
    setApiErrors({});
    if (mutation) {
      setLoading(true);
      try {
        await executeMutation({ variables: values });
      } catch (error) {
        // useMutation hook handles errors,
        // so we don't need to do anything here
      }
    } else {
      if (mutationOptions?.onCompleted) {
        mutationOptions.onCompleted(true);
      }
    }
  };

  return { form, loading, apiErrors, handleSubmit };
};
