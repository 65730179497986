import { CaretLeft } from '@phosphor-icons/react';
import {
  Col,
  ConfigProvider,
  Divider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  UploadFile,
} from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import { FormShare } from '../../../../__generated__/graphql';
import CommonButton from '../../../../components/primitives/CommonButton';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';
import { CommonPageTypes } from './pages.types';

export default function TemplateSetting({ onBack }: CommonPageTypes) {
  const [details, setDetails] = useState<FormShare>();
  const [form] = Form.useForm();
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [link, setLink] = useState<string>('');

  return (
    <>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
              fontWeightStrong: 500,
            },
            Input: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
              colorText: 'var(--content-primary)',
              colorBgContainer: 'var(--surface-secondary)',
            },
            InputNumber: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
              colorBgContainer: 'var(--surface-secondary)',
            },
            Select: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
              optionActiveBg: 'var(--surface-tertiary)',
              optionSelectedBg: 'var(--surface-tertiary)',
              colorBgContainer: '#ff0000',
            },
            Tag: {
              borderRadius: 16,
            },
            DatePicker: {
              borderRadius: 16,
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        {/* {!loading ? ( */}
        <Form
          autoComplete="off"
          initialValues={details}
          form={form}
          // onFieldsChange={handleAutoSave}
          onFieldsChange={(value) => {
            // eslint-disable-next-line no-console
            // console.log('onFieldsChange', value);
          }}
          className="edit-share-form"
        >
          <Flex
            gap={8}
            wrap
            align="center"
            justify="space-between"
            className="scroll-wrapper-header"
          >
            <Flex gap={12} align="center" className="w-full">
              <CommonButton
                type="text"
                onClick={() => onBack()}
                shape="circle"
                shadow={false}
                icon={<CaretLeft color="var(--content-primary)" size={20} />}
              />
            </Flex>
          </Flex>
          <div className="scroll-wrapper-body">
            <Row gutter={[12, 24]}>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Show on webapp
                  </Paragraph>
                  <Form.Item name="showOnWebApp">
                    <CommonSwitch type="primary" />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Show on website
                  </Paragraph>
                  <Form.Item name="showOnWeb">
                    <CommonSwitch type="primary" />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={10}>
                <Form.Item
                  label="Choose Category"
                  name="categoryId"
                  labelCol={{ span: 24 }}
                >
                  <Select
                    placeholder="Select category"
                    showSearch
                    options={[
                      {
                        value: 'test',
                        label: 'test',
                      },
                      {
                        value: 'test 1',
                        label: 'test 1',
                      },
                    ]}
                  ></Select>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Flex gap={8} justify="space-between" align="center">
                  <Paragraph className="mb-0 text-base semi-bold text-content-primary">
                    Weightage of template
                  </Paragraph>
                  <Form.Item name="weightage">
                    <InputNumber
                      min={0}
                      controls={false}
                      placeholder="Type here..."
                    />
                  </Form.Item>
                </Flex>
              </Col>
              <Col span={24}>
                <Divider className="mb-0 mt-0" dashed />
              </Col>
              <Col span={24}>
                <Form.Item name="title" label="Title" labelCol={{ span: 24 }}>
                  <Input placeholder="Type here..." />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="tags" label="Tags" labelCol={{ span: 24 }}>
                  <Select mode="tags" placeholder="Type here... " />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label="Description"
                  labelCol={{ span: 24 }}
                  valuePropName="value"
                  getValueFromEvent={(htmlContent) => htmlContent}
                >
                  <ReactQuill />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Form>
        {/* ) : (
            <FormLoading />
          )} */}
      </ConfigProvider>
    </>
  );
}
