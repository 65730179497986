import { useLazyQuery } from '@apollo/client';
import {
  CaretRight,
  CopySimple,
  LinkSimple,
  Plus,
} from '@phosphor-icons/react';
import { Flex, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { lowerCase, map, startCase } from 'lodash';
import { useEffect, useState } from 'react';
import {
  FormShare,
  ListFormShareSortOnField,
  SortOrder,
} from '../../../../__generated__/graphql';
import { copyToClipboard, getFormLink } from '../../../../common/utils';
import CommonButton from '../../../../components/primitives/CommonButton';
import useQueryParams from '../../../../hooks/useQueryParams';
import { GET_FORM_SHARES } from '../../graphql/queries';
import FormLoading from '../utils/FormLoading';
import { CommonPageTypes } from './pages.types';

export default function ShareList({ onBack, onClick }: CommonPageTypes) {
  const [getFormShares, { loading }] = useLazyQuery(GET_FORM_SHARES, {
    onError() {},
    fetchPolicy: 'network-only',
  });

  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;
  const [shares, setShares] = useState<FormShare[]>();

  useEffect(() => {
    getFormShares({
      variables: {
        where: {
          formId,
        },
        filter: {
          skip: 0,
          limit: 10,
        },
        sort: {
          sortBy: SortOrder.Asc,
          sortOn: ListFormShareSortOnField.CreatedAt,
        },
      },
      onCompleted: (res) => {
        setShares(res.formShares?.data as FormShare[]);
      },
    });
  }, []);

  const ShareCard = ({ item }: { item: FormShare }) => {
    return (
      <Flex
        className="share-card"
        justify="space-between"
        align="center"
        onClick={() => {
          if (onClick) {
            onClick(item.id as string);
          }
          onBack();
        }}
      >
        <Flex align="center" gap={12}>
          <CommonButton
            shape="circle"
            size="small"
            icon={<LinkSimple size={18} color="var(--content-primary)" />}
          ></CommonButton>
          <Flex vertical gap={4}>
            <Paragraph className="mb-0 text-base semi-bold text-content-primary">
              {item.title}
            </Paragraph>
            <Paragraph className="mb-0">
              {`${startCase(lowerCase(item?.formType || ''))} | ${startCase(lowerCase(item?.type || ''))}`}
            </Paragraph>
          </Flex>
        </Flex>
        <Flex gap={12} align="center">
          <Tooltip title="Copy Link">
            <CommonButton
              type="text"
              size="small"
              shape="circle"
              className="bg-surface-tertiary"
              shadow={false}
              icon={<CopySimple color="var(--content-primary)" size={16} />}
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(
                  getFormLink(item?.key || '', item?.embedConfigs),
                );
              }}
            ></CommonButton>
          </Tooltip>
          {/* future use */}
          {/* <Tooltip title="Delete">
            <Popconfirm
              title="Are you sure, you want to delete?"
              okText="Yes"
              className="hover-show"
              onConfirm={(e) => {
                e?.stopPropagation();
                // handleDelete(item.question_id as string);
              }}
              onCancel={(e) => e?.stopPropagation()}
              placement="right"
              okButtonProps={{
                block: true,
                icon: <DeleteOutlined />,
                danger: true,
              }}
              cancelButtonProps={{ block: true }}
              icon={null}
            >
              <CommonButton
                size="small"
                type="text"
                shape="circle"
                className="bg-surface-tertiary"
                shadow={false}
                onClick={(e) => e.stopPropagation()}
                icon={<Trash color="var(--danger-on-surface)" size={16} />}
              />
            </Popconfirm>
          </Tooltip> */}
          <CaretRight
            color="var(--content-tertiary)"
            size={16}
            className="cursor-pointer"
          />
        </Flex>
      </Flex>
    );
  };
  return (
    <>
      <Flex className="scroll-wrapper" vertical>
        <Flex
          gap={8}
          justify="space-between"
          align="center"
          className="scroll-wrapper-header"
        >
          <Flex vertical gap={2}>
            <Paragraph className="mb-0 text-m semi-bold text-content-primary">
              Share your form
            </Paragraph>
            <Paragraph className="mb-0 text-content-tertiary medium">
              Add a share to setup share settings.
            </Paragraph>
          </Flex>

          <CommonButton type="text" icon={<Plus size={14} />}>
            Add
          </CommonButton>
        </Flex>
        {loading ? (
          <FormLoading />
        ) : (
          <div className="scroll-wrapper-body">
            <Flex vertical gap={16}>
              {map(shares, (item: FormShare) => {
                return <ShareCard item={item} />;
              })}
            </Flex>
          </div>
        )}
      </Flex>
    </>
  );
}
