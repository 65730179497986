import { ValidationRule } from '../ValidationRules';
import { FormField } from './FormField';

export class SelectField extends FormField {
  constructor(
    id: string,
    label: string,
    placeholder: string,
    required: boolean,
    // eslint-disable-next-line no-unused-vars
    public options: { value: string; label: string }[],
    validationRules: ValidationRule[] = [],
  ) {
    super(id, 'select', label, required, placeholder, validationRules);
  }

  validate(value: string): string | null {
    if (!this.options.some((option) => option.value === value)) {
      return 'Invalid option selected';
    }
    for (const rule of this.validationRules) {
      const error = rule(value);
      if (error) return error;
    }
    return null;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      options: this.options,
    };
  }
}
