import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Form, Row, Spin } from 'antd';
import { RcFile, UploadChangeParam, UploadFile } from 'antd/es/upload';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { UpdateUserProfileMutation, User } from '../../__generated__/graphql';
import { DeleteIcon } from '../../assets/icons';
import {
  ALLOWED_FILE_TYPES,
  DROPDOWN_LIST,
  ROUTES,
} from '../../common/constants';
import {
  beforeUpload,
  formValidation,
  getBase64,
  handleGraphQlSuccess,
} from '../../common/utils';
import useRouter from '../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import { FormFieldType } from '../auth/auth.types';
import { UPDATE_USER_PROFILE } from '../auth/graphql/mutations';
import { FormField } from '../form/FormField';
import { useForm } from '../form/hooks/useForm';
import { DELETE_USER_ACCOUNT } from './graphql/mutations';
import { GET_SIGNED_URL } from './graphql/queries';
import DeleteAccountModal from './modals/DeleteAccountModal';

const OTP_MODE = process.env.REACT_APP_OTP_MODE === 'true' ? true : false;

export default function ProfileForm() {
  const [currentImage, setCurrentImage] = useState<UploadFile | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const {
    form: profileForm,
    loading: profileLoading,
    apiErrors: profileApiErrors,
    handleSubmit: handleProfileSubmit,
  } = useForm(UPDATE_USER_PROFILE, {
    onCompleted: (data: UpdateUserProfileMutation) => {
      handleGraphQlSuccess(data.updateUserProfile?.message);
    },
  });

  const [formChanged, setFormChanged] = useState(false);
  const { navigate } = useRouter();
  const {
    state: { currentUser },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const [deleteUserAccount, { loading: deleteLoading }] = useMutation(
    DELETE_USER_ACCOUNT,
    {
      onError: () => {},
    },
  );

  const [getSignedUrl] = useLazyQuery(GET_SIGNED_URL, {
    onError: () => {},
  });

  useEffect(() => {
    if (!isEmpty(currentUser)) {
      profileForm.setFieldsValue({
        ...currentUser,
        countryCode: currentUser.countryCode?.split('+')?.[1],
      });
      setImageUrl(currentUser.profileImage || '');
    }
  }, [currentUser]);

  const handleChange = async (info: UploadChangeParam) => {
    if (beforeUpload(info.file)) {
      setCurrentImage(info.file);
      setImageUrl(
        (await getBase64(
          info.file.originFileObj as UploadFile['originFileObj'] as RcFile,
        )) as string,
      );
    }
  };

  const handleFinish = async (values: User) => {
    setLoading(true);

    const userObj = {
      name: values?.name?.trim(),
      profileImage: imageUrl,
    };

    if (currentImage) {
      getSignedUrl({
        variables: {
          data: {
            fileName: currentImage?.name,
          },
        },
        onCompleted: async ({ getProfileImageUploadSignedUrl }) => {
          try {
            const response = await axios?.put(
              getProfileImageUploadSignedUrl?.signedUrl as string,
              currentImage,
            );
            if (response?.status === 200) {
              userObj.profileImage =
                getProfileImageUploadSignedUrl?.key as string;
              // updateUserProfileMutate({
              //   variables: {
              //     data: userObj,
              //   },
              // });
            }
          } catch (err) {
            return err;
          } finally {
            setLoading(false);
          }
        },
        onError: () => {
          setLoading(false);
        },
      });
    } else {
      setLoading(false);
      handleProfileSubmit({ data: userObj });
    }
  };

  const handleOnOk = () => {
    deleteUserAccount({
      onCompleted: (data) => {
        handleGraphQlSuccess(data.deleteAccount?.message);
        dispatch({ type: AppActionType.logout });
        navigate(ROUTES.LOGIN);
      },
    });
  };

  const handleOnCancel = () => {
    setOpen(false);
  };

  return (
    <div className="profile-form">
      <Spin spinning={loading || profileLoading}>
        <Form
          form={profileForm}
          name="contact-form"
          onFinish={handleFinish}
          layout="vertical"
          onFieldsChange={() => setFormChanged(true)}
          scrollToFirstError
          initialValues={{ countryCode: '+91' }}
        >
          <FormField
            name="profileImage"
            type={FormFieldType.FILE}
            apiErrors={profileApiErrors}
            inputProps={{
              placeholder: 'Enter name',
              name: 'avatar',
              listType: 'picture-circle',
              className: 'avatar-uploader',
              beforeUpload: () => false,
              showUploadList: false,
              accept: ALLOWED_FILE_TYPES.profileImage,
              onChange: handleChange,
              currentImage: currentImage,
            }}
          />
          <FormField
            name="name"
            label="Name"
            type={FormFieldType.TEXT}
            apiErrors={profileApiErrors}
            rules={[
              formValidation.required,
              formValidation.whitespace,
              formValidation.name,
            ]}
            inputProps={{ placeholder: 'Enter name' }}
          />
          <FormField
            name="email"
            label="Email"
            type={FormFieldType.TEXT}
            apiErrors={profileApiErrors}
            rules={[
              formValidation.required,
              formValidation.whitespace,
              formValidation.email,
            ]}
            inputProps={{ placeholder: 'Enter email', disabled: true }}
          />
          <Row gutter={[12, 12]}>
            <Col span={24} md={6}>
              <FormField
                name="countryCode"
                type={FormFieldType.SELECT}
                label="Phone Number"
                apiErrors={profileApiErrors}
                rules={OTP_MODE ? [formValidation.required] : []}
                options={DROPDOWN_LIST.countryList}
                inputProps={{ placeholder: 'Code', disabled: true }}
              />
            </Col>
            <Col span={24} md={18} className="flex items-end">
              <FormField
                name="phoneNumber"
                label=""
                type={FormFieldType.PHONE_NUMBER}
                apiErrors={profileApiErrors}
                rules={
                  OTP_MODE
                    ? [formValidation.required, formValidation.number]
                    : [formValidation.number]
                }
                inputProps={{ placeholder: 'Enter phone', disabled: true }}
                formItemProps={{ className: 'w-full' }}
              />
            </Col>
          </Row>
          <Form.Item className="mb-32">
            <Button
              title="Save Changes"
              htmlType="submit"
              // border={false}
              disabled={!formChanged}
            />
          </Form.Item>
          <Row>
            <Col>
              <Form.Item className="mb-0">
                <Link to={ROUTES.LOGOUT}>
                  <Button
                    title="Sign Out"
                    htmlType="button"
                    type="default"
                    // border={false}
                  />
                </Link>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item className="mb-0">
                <Button
                  title={deleteLoading ? 'Deleting...' : 'Delete Account'}
                  htmlType="button"
                  type="link"
                  loading={deleteLoading}
                  // border={false}
                  icon={<DeleteIcon />}
                  onClick={() => setOpen(true)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Spin>
      {/* delete account modal */}
      <DeleteAccountModal
        onOk={handleOnOk}
        open={open}
        onCancel={handleOnCancel}
        okLoading={deleteLoading}
      />
    </div>
  );
}
