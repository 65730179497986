import { Flex, Skeleton } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { SparkleAISvg } from '../../../../assets/icons';

type AIProcessingFormType = {
  type?: 'EDITOR' | 'PREVIEW';
};
export default function AIProcessingForm({
  type = 'EDITOR',
}: AIProcessingFormType) {
  if (type === 'EDITOR') {
    return (
      <div className="scroll-wrapper flex justify-center items-center">
        <Flex vertical gap={12} align="center">
          <SparkleAISvg />
          <Flex vertical gap={8} align="center">
            <Title
              level={2}
              className="mb-0 text-content-primary semi-bold text-center font-secondary"
            >
              AI is generating your questions
            </Title>
            <Paragraph className="mb-0 text-content-secondary text-base medium text-center">
              Hang tight! It will be worth it.
            </Paragraph>
          </Flex>
        </Flex>
      </div>
    );
  }
  return <Skeleton active />;
}
