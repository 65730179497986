import { Flex, SwitchProps } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import PremiumLogo from '../../../../components/common/PremiumLogo';
import CommonSwitch from '../../../../components/primitives/CommonSwitch';

type CommonCheckLayout = {
  title: string;
  description: string;
  checked: boolean;
  isPremium?: boolean;
  onChange: (value: boolean) => void;
  switchProps?: SwitchProps;
};

export default function CommonCheckLayout({
  title,
  description,
  checked,
  isPremium,
  onChange,
  switchProps,
}: CommonCheckLayout) {
  return (
    <Flex gap={16} justify="space-between" align="flex-start">
      <Flex vertical gap={2}>
        <Flex gap={6} align="center">
          <Paragraph className="mb-0 text-base semi-bold text-content-primary">
            {title}
          </Paragraph>
          {isPremium && <PremiumLogo />}
        </Flex>
        <Paragraph className="mb-0 text-content-tertiary medium">
          {description}
        </Paragraph>
      </Flex>
      <CommonSwitch
        type="primary"
        checked={checked}
        onChange={(val) => onChange(val)}
        {...switchProps}
      />
    </Flex>
  );
}
