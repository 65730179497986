import {
  Auth,
  ConfirmationResult,
  linkWithPhoneNumber,
  RecaptchaVerifier,
  signInWithPopup,
  User,
} from 'firebase/auth';
import { auth, googleProvider } from './config';

// sign in, sign up with Google popup
export const signInWithGooglePopup = async () => {
  try {
    const response = await signInWithPopup(auth, googleProvider);
    return { token: response || null };
  } catch (error) {
    return { token: null };
  }
};

// send OTP
export const sendVerificationCode = async (
  phone: string,
  auth: Auth,
  recaptchaVerifier: RecaptchaVerifier,
) => {
  const response = await linkWithPhoneNumber(
    auth.currentUser as User,
    phone,
    recaptchaVerifier,
  );
  return response;
};

export const verifyCode = async (
  ConfirmationResult: ConfirmationResult,
  otp: string,
) => {
  const response = await ConfirmationResult.confirm(otp);
  return response.user;
};
