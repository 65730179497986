import { ConfirmationResult } from 'firebase/auth';
import { User } from '../../__generated__/graphql';

export type InputDataType = {
  accessToken?: string;
  name?: string;
  phoneNumber?: string;
  countryCode?: string;
  user?: User;
  confirmationResult?: ConfirmationResult;
  refreshToken?: string;
};

export type ContactFormType = {
  name: string;
  phoneNumber: string;
  countryCode: string;
};

export type OTPFormType = {
  otp: string;
};

export type MetaDataFormType = {
  industry: string;
  position: string;
  discover: string;
};

export type TokenType = {
  accessToken?: string;
};

export type ErrorTypes = {
  phoneNumber?: string;
  otp?: string;
  deleteValue?: string;
};

export type SlideTypes = {
  imgUrl: string;
  title: string;
  description: string;
};

export enum FormFieldType {
  // eslint-disable-next-line no-unused-vars
  SHORT_TEXT = 'SHORT_TEXT',
  // eslint-disable-next-line no-unused-vars
  LONG_TEXT = 'LONG_TEXT',
  // eslint-disable-next-line no-unused-vars
  TEXT = 'TEXT',
  // eslint-disable-next-line no-unused-vars
  TEXT_AREA = 'TEXT_AREA',
  // eslint-disable-next-line no-unused-vars
  PHONE_NUMBER = 'PHONE_NUMBER',
  // eslint-disable-next-line no-unused-vars
  NUMBER = 'NUMBER',
  // eslint-disable-next-line no-unused-vars
  EMAIL = 'EMAIL',
  // eslint-disable-next-line no-unused-vars
  SELECT = 'SELECT',
  // eslint-disable-next-line no-unused-vars
  MULTI_SELECT = 'MULTI_SELECT',
  // eslint-disable-next-line no-unused-vars
  TIME = 'TIME',
  // eslint-disable-next-line no-unused-vars
  DATE = 'DATE',
  // eslint-disable-next-line no-unused-vars
  CONTACT_INFO = 'CONTACT_INFO',
  // eslint-disable-next-line no-unused-vars
  LINEAR_SCALE = 'LINEAR_SCALE',
  // eslint-disable-next-line no-unused-vars
  ADDRESS = 'ADDRESS',
  // eslint-disable-next-line no-unused-vars
  FILE = 'FILE',
  // eslint-disable-next-line no-unused-vars
  RATE = 'RATE',
  // eslint-disable-next-line no-unused-vars
  PAYMENT = 'PAYMENT',
  // eslint-disable-next-line no-unused-vars
  CALENDAR = 'CALENDAR',
  // eslint-disable-next-line no-unused-vars
  DATE_RANGE = 'DATE_RANGE',
  // eslint-disable-next-line no-unused-vars
  DROPDOWN = 'DROPDOWN',
  // eslint-disable-next-line no-unused-vars
  PICTURE_CHOICE = 'PICTURE_CHOICE',
  // eslint-disable-next-line no-unused-vars
  WELCOME_PAGE = 'WELCOME_PAGE',
  // eslint-disable-next-line no-unused-vars
  END_PAGE = 'END_PAGE',
}
