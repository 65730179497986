import { useLazyQuery } from '@apollo/client';
import {
  CaretDown,
  CurrencyDollar,
  CurrencyInr,
  GenderFemale,
  GenderMale,
  Phone,
} from '@phosphor-icons/react';
import {
  Button,
  Col,
  ConfigProvider,
  Flex,
  Form,
  Input,
  InputNumber,
  Row,
  Segmented,
  Select,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Accent, AiTone, Gender } from '../../../__generated__/graphql';
import { DROPDOWN_LIST } from '../../../common/constants';
import {
  formValidation,
  formValidatorRules,
  handleGraphQlSuccess,
} from '../../../common/utils';
import useQueryParams from '../../../hooks/useQueryParams';
import {
  InitiateVoiceFormSubmissionInputType,
  Palette,
} from '../../form-builder/model/form/form.types';
import { INITIATE_VOICE_CALL } from '../graphql/queries';

export default function CallPreview({ activeTheme }: { activeTheme: Palette }) {
  const [form] = useForm();
  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;
  const [initiateVoiceCallMutate, { loading }] = useLazyQuery(
    INITIATE_VOICE_CALL,
    {
      fetchPolicy: 'network-only',
      onError: () => {},
    },
  );
  const prefixSelector = (
    <Form.Item name="prefix" noStyle>
      <Select
        style={{ width: 150 }}
        options={DROPDOWN_LIST.countryList}
        showSearch
        suffixIcon={<CaretDown size={18} color={activeTheme?.textColor} />}
      ></Select>
    </Form.Item>
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleFinish = (formValues: InitiateVoiceFormSubmissionInputType) => {
    const { prefix, phone, ...restFormValues } = formValues;
    const payload = {
      ...restFormValues,
      formId: formId,
      phoneNumber: `${prefix}${phone}`,
    };
    initiateVoiceCallMutate({
      variables: {
        data: payload,
      },
      onCompleted: (res) => {
        handleGraphQlSuccess(res.initiateVoiceFormSubmission?.message);
        form.resetFields();
      },
    });
  };
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBgContainer: 'var(--surface-secondary)',
          colorPrimary: '#a9aeb1',
          fontFamily: activeTheme.fontFamily,
        },
        components: {
          Form: {
            labelColor: activeTheme.textColor,
            labelFontSize: 14,
            verticalLabelPadding: '10px 0',
            itemMarginBottom: 0,
            fontWeightStrong: 500,
          },
          Segmented: {
            trackBg: activeTheme.textColor,
            itemColor: activeTheme.bgPrimary,
            itemSelectedColor: activeTheme.textColor,
            itemSelectedBg: activeTheme.bgPrimary,
          },
          Input: {
            borderRadius: 16,
            // colorBorder: 'var(--border-primary)',
            colorText: activeTheme.textColor,
            colorTextPlaceholder: activeTheme.textColor,
            colorBgContainer: 'transparent',
            colorBorder: activeTheme.textColor,
          },
          InputNumber: {
            borderRadius: 16,
            colorText: activeTheme.textColor,
            colorTextPlaceholder: activeTheme.textColor,
            colorBgContainer: 'transparent',
            colorBorder: activeTheme.textColor,
          },
          Select: {
            colorText: activeTheme.textColor,
            optionActiveBg: activeTheme.bgPrimary,
            colorBgElevated: activeTheme.bgPrimary,
            optionSelectedColor: activeTheme.bgPrimary,
            optionSelectedBg: activeTheme.textColor,
            colorBorder: activeTheme.textColor,
            selectorBg: 'transparent',
          },
          Button: {
            colorPrimary: activeTheme.textColor,
            colorPrimaryHover: activeTheme.textColor,
            colorTextLightSolid: activeTheme.bgPrimary,
            defaultColor: activeTheme?.textColor,
            defaultBg: 'transparent',
            defaultBorderColor: activeTheme?.textColor,
            defaultHoverBg: activeTheme?.textColor,
            defaultHoverColor: activeTheme?.bgPrimary,
          },
          DatePicker: {
            borderRadius: 16,
            colorBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Form
        layout="vertical"
        autoComplete="off"
        form={form}
        initialValues={{
          gender: Gender.Male,
          accent: Accent.American,
          tone: AiTone.Witty,
          prefix: '+91',
        }}
        onFinish={handleFinish}
      >
        <section className="form-selected-item">
          <Row gutter={[16, 24]}>
            <Col lg={24} xl={16}>
              <Form.Item label="Gender" name="gender">
                <Segmented
                  className="app-segments"
                  options={[
                    {
                      value: Gender.Male,
                      label: 'Male',
                      icon: <GenderMale size={18} />,
                    },
                    {
                      value: Gender.Female,
                      label: 'Female',
                      icon: <GenderFemale size={18} />,
                    },
                  ]}
                  onChange={(val) => {
                    form.setFieldValue('gender', val);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={24} xl={16}>
              <Form.Item label="accent" name="accent">
                <Segmented
                  className="app-segments"
                  options={[
                    {
                      value: Accent.American,
                      label: 'American',
                      icon: <CurrencyDollar size={18} />,
                    },
                    {
                      value: Accent.Indian,
                      label: 'Indian',
                      icon: <CurrencyInr size={18} />,
                    },
                  ]}
                  onChange={(val) => {
                    form.setFieldValue('accent', val);
                  }}
                />
              </Form.Item>
            </Col>
            <Col lg={12}>
              <Form.Item label="Tone of AI" name="tone" labelCol={{ span: 24 }}>
                <Select
                  showSearch
                  className="w-full"
                  placeholder="Select"
                  optionFilterProp="label"
                  options={DROPDOWN_LIST.personaList}
                  suffixIcon={
                    <CaretDown size={18} color={activeTheme?.textColor} />
                  }
                />
              </Form.Item>
            </Col>
            <Col lg={20}>
              <Form.Item
                label="Name"
                name="name"
                labelCol={{ span: 24 }}
                rules={[formValidatorRules.required]}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
            </Col>
            <Col lg={20}>
              <Form.Item
                label="Phone number"
                name="phone"
                labelCol={{ span: 24 }}
                rules={[formValidation.required]}
              >
                <InputNumber
                  addonBefore={prefixSelector}
                  precision={0}
                  controls={false}
                  style={{ width: '100%' }}
                  placeholder="Type here..."
                />
              </Form.Item>
            </Col>
            <Col lg={24}>
              <Flex justify="center">
                <Button
                  type="primary"
                  htmlType="submit"
                  shape="round"
                  loading={loading}
                  size="large"
                  icon={<Phone size={18} weight="fill" />}
                >
                  Call
                </Button>
              </Flex>
            </Col>
          </Row>
        </section>
      </Form>
    </ConfigProvider>
  );
}
