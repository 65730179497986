import { useMutation } from '@apollo/client';
import { Info } from '@phosphor-icons/react';
import { Form, Input, Modal, ModalProps } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import { useContext, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import { UnpublishFormData } from '../../__generated__/graphql';
import { formValidation, handleGraphQlSuccess } from '../../common/utils';
import { UNPUBLISH_FORM } from '../../modules/form/graphql/mutations';
import { AppActionType, AppContextType } from '../../types/appContext.type';
import CommonButton from '../primitives/CommonButton';

export default function UnpublishModal({
  open,
  onCancel,
  onSuccess,
  ...rest
}: ModalProps & { onSuccess: () => void }) {
  const [form] = useForm();

  const {
    dispatch,
    state: { formSettings },
  } = useContext(AppContext) as AppContextType;

  const [unPublishMutate, { loading }] = useMutation(UNPUBLISH_FORM, {
    onError: () => {},
  });

  const handleFinish = ({ closingMessage }: UnpublishFormData) => {
    if (formSettings?.id) {
      unPublishMutate({
        variables: {
          data: {
            closingMessage,
          },
          where: {
            formId: formSettings?.id,
          },
        },
        onCompleted: (res) => {
          handleGraphQlSuccess(res.unpublishForm?.message);
          dispatch({
            type: AppActionType.setFormSettings,
            data: {
              status: res.unpublishForm?.data?.status,
            },
          });
          onSuccess();
        },
      });
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      closingMessage: formSettings?.settings?.[0]?.closingMessage,
    });
  }, [form, formSettings]);

  return (
    <Modal
      title={
        <Title level={3} className="text-content-primary semi-bold">
          Settings
        </Title>
      }
      open={open}
      footer={false}
      width={900}
      onCancel={onCancel}
      {...rest}
    >
      <div className="flex items-center justify-between mt-24 mb-16">
        <div>
          <Paragraph className="mb-0 text-base semi-bold text-content-primary mb-2">
            Unpublish this form
          </Paragraph>
          <Paragraph className="mb-0 text-sm text-content-tertiary">
            Unpublishing will close this form for any further responses.
          </Paragraph>
        </div>
        <CommonButton
          type="primary"
          onClick={() => {
            form.submit();
          }}
          disabled={loading}
        >
          {loading ? 'Unpublishing...' : 'Unpublish'}
        </CommonButton>
      </div>
      <div>
        <Form layout="vertical" form={form} onFinish={handleFinish}>
          <Form.Item
            className="text-content-secondary medium m-0"
            label="Closing message"
            name="closingMessage"
            rules={[formValidation.whitespace]}
            tooltip={{
              title:
                'This message will be shown when form is closed/unpublished',
              icon: <Info size={20} color="var(--content-primary)" />,
            }}
          >
            <Input placeholder="e.g. Thanks for dropping by, we're no longer open for conversation here..." />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}
