import { useMutation } from '@apollo/client';
import { ArrowsClockwise, CaretDown, CaretUp } from '@phosphor-icons/react';
import { Col, Collapse, ConfigProvider, Form, Input, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import { Form as FormTypes } from '../../../../__generated__/graphql';
import { formValidation } from '../../../../common/utils';
import CommonButton from '../../../../components/primitives/CommonButton';
import useQueryParams from '../../../../hooks/useQueryParams';
import { UPDATE_FORM_STRUCTURE } from '../../graphql/mutations';
import CollapseHeader from '../utils/CollapseHeader';
import { OverviewFormTypes } from './pages.types';

export default function OverviewForm({
  overviewText,
  promptText,
  onFinish,
}: OverviewFormTypes) {
  const [updateStructureMutate, { loading }] = useMutation(
    UPDATE_FORM_STRUCTURE,
    {
      onError() {},
    },
  );

  const { getQueryParam } = useQueryParams();
  const formId = getQueryParam('id') as string;
  const [form] = useForm();

  return (
    <Collapse
      className="mb-24"
      expandIconPosition="right"
      expandIcon={({ isActive }) =>
        isActive ? (
          <CaretUp size={16} color="var(--content-tertiary)" />
        ) : (
          <CaretDown size={16} color="var(--content-tertiary)" />
        )
      }
    >
      <Collapse.Panel
        header={
          <CollapseHeader title="Initial Prompt" description={promptText} />
        }
        key="1"
      >
        <div>
          <ConfigProvider
            theme={{
              components: {
                Input: {
                  colorBorder: 'var(--border-primary)',
                  borderRadius: 8,
                  lineWidth: 1,
                },
                Form: {
                  labelColor: 'var(--content-primary)',
                  itemMarginBottom: 8,
                  labelHeight: 20,
                },
              },
            }}
          >
            <Form
              form={form}
              initialValues={{
                overview: overviewText,
              }}
              labelCol={{ span: 24 }}
              onFinish={(formValues) => {
                updateStructureMutate({
                  variables: {
                    where: {
                      formId,
                    },
                    data: {
                      overview: formValues?.overview,
                    },
                  },
                  onCompleted: (res) => {
                    onFinish(res.updateFormStructure?.data as FormTypes);
                  },
                });
              }}
            >
              <div className="information-wrapper">
                <Form.Item
                  name="overview"
                  label="Overview"
                  rules={[formValidation.required]}
                >
                  <Input.TextArea
                    placeholder="Type here..."
                    autoSize={{ minRows: 3 }}
                  />
                </Form.Item>
                <Row gutter={[16, 16]}>
                  <Col lg={14} xl={16}>
                    <Paragraph className="mb-0 text-danger-on-surface text-meta">
                      Note: All data fields will change if you choose to
                      regenerate the prompt.
                    </Paragraph>
                  </Col>
                  <Col lg={10} xl={8}>
                    <div className="flex justify-end">
                      <CommonButton
                        htmlType="submit"
                        type="text"
                        size="small"
                        loading={loading}
                        icon={
                          <ArrowsClockwise
                            size={16}
                            weight="fill"
                            color="var(--content-primary)"
                          />
                        }
                      >
                        Regenerate
                      </CommonButton>
                    </div>
                  </Col>
                </Row>
              </div>
            </Form>
          </ConfigProvider>
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
