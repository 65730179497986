import { CheckOutlined } from '@ant-design/icons';
import { Button, Col, ConfigProvider, Form, Row, Space } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Dayjs } from 'dayjs';
import { includes, map } from 'lodash';
import { ReactNode, useEffect, useState } from 'react';
import { DROPDOWN_LIST, getSliderMarks } from '../../../common/constants';
import {
  formValidation,
  getAcceptedFileTypes,
  getDisabledDates,
} from '../../../common/utils';
import classicFormTheme from '../../../styles/theme/classicFormConfig';
import { FormFieldType } from '../../auth/auth.types';
import { FormSchemaType } from '../../form-builder/model/form/form.types';
import { FormFieldConversational } from '../FormFieldConversational';

type ClassicUIPreviewType = {
  itemList: FormSchemaType[];
};

export default function ClassicUIPreview({ itemList }: ClassicUIPreviewType) {
  const [form] = useForm();
  const [apiErrors] = useState<Record<string, string>>({});

  const handleOnFinish = () => {};

  useEffect(() => {
    form.resetFields();
  }, [itemList]);

  const renderItemUI = (item: FormSchemaType) => {
    const commonRules = item?.is_required ? [formValidation.required] : [];
    switch (item.answer_type) {
      case FormFieldType.SHORT_TEXT:
        return (
          <FormFieldConversational
            name={item?.answer_store_key as string}
            label={item?.question_text}
            type={FormFieldType.SHORT_TEXT}
            apiErrors={apiErrors}
            rules={[...commonRules, formValidation.whitespace]}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
            }}
            formItemProps={{ className: 'w-full' }}
          />
        );
      case FormFieldType.EMAIL:
        return (
          <FormFieldConversational
            name={item?.answer_store_key as string}
            label={item?.question_text}
            type={FormFieldType.SHORT_TEXT}
            apiErrors={apiErrors}
            rules={[
              ...commonRules,
              formValidation.email,
              formValidation.whitespace,
            ]}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
            }}
            formItemProps={{ className: 'w-full' }}
          />
        );
      case FormFieldType.FILE:
        return (
          <FormFieldConversational
            name={item?.answer_store_key as string}
            label={item?.question_text}
            type={FormFieldType.FILE}
            apiErrors={apiErrors}
            rules={commonRules}
            inputProps={{
              multiple: (item?.config?.max_file_count as number) > 1 ?? false,
              maxCount: item?.config?.max_file_count,
              maxSize: item?.config?.max_file_size,
              accept: getAcceptedFileTypes(item?.config?.file_type),
            }}
            formItemProps={{ className: 'w-full' }}
          />
        );
      case FormFieldType.LONG_TEXT:
        return (
          <FormFieldConversational
            name={item?.answer_store_key as string}
            label={item?.question_text}
            type={FormFieldType.LONG_TEXT}
            apiErrors={apiErrors}
            rules={[...commonRules, formValidation.whitespace]}
            inputProps={{
              placeholder: 'Type here...',
              autoSize: { minRows: 1 },
              maxLength: item?.config?.character_limit,
            }}
            formItemProps={{ className: 'w-full' }}
          />
        );
      case FormFieldType.NUMBER:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.NUMBER}
              apiErrors={apiErrors}
              rules={commonRules}
              inputProps={{
                placeholder: 'Type here...',
                min: item?.config?.min_number,
                max: item?.config?.max_number,
                className: 'w-full',
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );
      case FormFieldType.PHONE_NUMBER:
        return (
          // <Space direction="vertical" className="w-full" size={48}>
          //   <FormFieldConversational
          //     name={item?.answer_store_key as string}
          //     label={item?.question_text}
          //     type={FormFieldType.PHONE_NUMBER}
          //     apiErrors={apiErrors}
          //     rules={commonRules}
          //     inputProps={{
          //       placeholder: 'Type here...',
          //     }}
          //     formItemProps={{ className: 'w-full' }}
          //   />
          // </Space>
          <Row gutter={16}>
            <Col md={24}>
              <FormFieldConversational
                name="countryCode"
                label={item?.question_text}
                type={FormFieldType.DROPDOWN}
                apiErrors={apiErrors}
                rules={[...commonRules, formValidation.whitespace]}
                inputProps={{
                  placeholder: 'Select',
                  options: DROPDOWN_LIST.countryList,
                  showSearch: true,
                }}
                formItemProps={{ className: 'w-full', required: false }}
              />
            </Col>
            <Col md={24}>
              <FormFieldConversational
                name="userResponse"
                // label={item?.question_text}
                type={FormFieldType.NUMBER}
                apiErrors={apiErrors}
                rules={[...commonRules]}
                inputProps={{
                  placeholder: 'Type here...',
                  controls: false,
                  className: 'w-full',
                }}
                formItemProps={{ className: 'w-full', required: false }}
              />
            </Col>
          </Row>
        );
      case FormFieldType.RATE:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.RATE}
              apiErrors={apiErrors}
              rules={commonRules}
              inputProps={{
                placeholder: 'Type here...',
                count: item?.config?.max_rating,
                onChange: () => {},
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );
      case FormFieldType.LINEAR_SCALE:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.LINEAR_SCALE}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                min: item?.config?.min_rating,
                max: item?.config?.max_rating,
                minLabel: item?.config?.min_label,
                maxLabel: item?.config?.max_label,
                className: 'w-full',
                marks: getSliderMarks(Number(item?.config?.max_rating)),
                onChange: () => {},
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );
      case FormFieldType.SELECT:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.SELECT}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                options: item.options ?? [],
                onChange: () => {},
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );
      case FormFieldType.DATE:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.DATE}
              apiErrors={apiErrors}
              rules={commonRules}
              inputProps={{
                placeholder: 'Select date',
                onChange: () => {},
                format: 'YYYY-MM-DD',
                disabledDate: (date: Dayjs) =>
                  getDisabledDates(date, {
                    allowFuture: item?.config?.allow_future_date,
                    allowPast: item?.config?.allow_past_date,
                  }),
                allowFutureDates: item?.config?.allow_future_date,
                allowPastDates: item?.config?.allow_past_date,
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );
      case FormFieldType.TIME:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.TIME}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Select time',
                onChange: (value: string) => {
                  form.setFieldValue(item?.answer_store_key as string, value);
                },
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );
      case FormFieldType.MULTI_SELECT:
        return (
          <Space direction="vertical" className="w-full" size={48}>
            <FormFieldConversational
              name={item?.answer_store_key as string}
              label={item?.question_text}
              type={FormFieldType.MULTI_SELECT}
              apiErrors={apiErrors}
              inputProps={{
                placeholder: 'Type here...',
                options: item?.options ?? [],
                onChange: (value: string[]) => {
                  form.setFieldValue(item?.answer_store_key as string, value);
                },
              }}
              formItemProps={{ className: 'w-full' }}
            />
          </Space>
        );

      default:
        break;
    }
  };

  const handleRenderItemList = (list: FormSchemaType[]): ReactNode[] => {
    const allowedTypes = [FormFieldType.ADDRESS, FormFieldType.CONTACT_INFO];
    return map(list, (item) => {
      if (item?.nested && includes(allowedTypes, item.answer_type)) {
        return handleRenderItemList(item.nested);
      }
      return !item?.hidden && <div>{renderItemUI(item)}</div>;
    });
  };

  return (
    <ConfigProvider theme={classicFormTheme}>
      <Form
        form={form}
        onFinish={handleOnFinish}
        autoComplete="off"
        className="classic-form-renderer form-controls"
        scrollToFirstError
        initialValues={{ countryCode: '+91' }}
      >
        {handleRenderItemList(itemList)}
        {itemList?.length > 0 && (
          <div>
            <Button htmlType="submit" icon={<CheckOutlined />}>
              Submit
            </Button>
          </div>
        )}
      </Form>
    </ConfigProvider>
  );
}
