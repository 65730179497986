import { gql } from '../../../__generated__';

export const GET_FORM_LIST = gql(`
 query Forms($filter: ListFormFilter, $sort: [ListFormSort!]) {
  forms(filter: $filter, sort: $sort) {
    count
    data {
      designs {
        theme {
          type
          configs
        }
        themeVariation {
          type
          colorPalette
        }
        themeMode
      }
      status
      totalSubmissionsCount
      notViewedSubmissionsCount
      id
      title
    }
  }
}`);
