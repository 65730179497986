import { useMutation } from '@apollo/client';
import { CaretLeft, Plus, Sparkle } from '@phosphor-icons/react';
// import { ConfigProvider, Divider, Flex, Form, Input } from 'antd';
import { ConfigProvider, Divider, Flex, Form, Input } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { FormStructure } from '../../../../__generated__/graphql';
import { formValidation } from '../../../../common/utils';
import CommonButton from '../../../../components/primitives/CommonButton';
import { ADD_FORM_FIELDS } from '../../graphql/mutations';
import { CommonPageTypes } from './pages.types';

export const AddQuestion: React.FC<
  CommonPageTypes & {
    onManualQuestionAdd: () => void;
    onAIQuestionAdd: (data: FormStructure) => void;
    formId: string;
  }
> = ({ onBack, onManualQuestionAdd, onAIQuestionAdd, formId }) => {
  const [generateAIQuestionMutate, { loading }] = useMutation(ADD_FORM_FIELDS, {
    fetchPolicy: 'network-only',
    onError: () => {},
  });

  const handleFinish = ({ prompt }: { prompt: string }) => {
    generateAIQuestionMutate({
      variables: {
        data: {
          prompt,
        },
        where: {
          formId,
        },
      },
      onCompleted: (res) => {
        onAIQuestionAdd(res.addFormFields?.data as FormStructure);
      },
    });
  };
  return (
    <section>
      <ConfigProvider
        theme={{
          token: {
            colorBgContainer: 'var(--surface-secondary)',
            colorPrimary: '#a9aeb1',
          },
          components: {
            Form: {
              labelColor: 'var(--content-secondary)',
              labelFontSize: 14,
              verticalLabelPadding: 0,
              itemMarginBottom: 0,
            },
            Input: {
              borderRadius: 16,
              colorText: 'var(--content-primary)',
              colorBorder: 'var(--border-primary)',
            },
          },
        }}
      >
        <Flex gap={12} wrap align="center" className="scroll-wrapper-header">
          <CommonButton
            type="text"
            onClick={() => onBack()}
            shape="circle"
            shadow={false}
            icon={<CaretLeft color="var(--content-primary)" size={20} />}
          />
          <Paragraph className="mb-0 text-content-primary text-base semi-bold">
            Add Question
          </Paragraph>
        </Flex>
        <div className="scroll-wrapper-body">
          <Form onFinish={handleFinish}>
            <Form.Item
              label="What information would you like to collect?"
              name="prompt"
              labelCol={{ span: 24 }}
              required={true}
              rules={[formValidation.required, formValidation.whitespace]}
            >
              <Input.TextArea
                placeholder="E.g: I want to take photo for employee ID."
                autoSize={{ minRows: 3 }}
              />
            </Form.Item>
            <Form.Item>
              <Flex justify="flex-end" className="mt-8">
                <CommonButton
                  icon={<Sparkle size={16} weight="fill" />}
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Generate
                </CommonButton>
              </Flex>
            </Form.Item>
          </Form>
          <div className="mt-24 mb-24">
            <Divider dashed>
              <Paragraph className="mb-0 medium text-content-secondary">
                OR
              </Paragraph>
            </Divider>
          </div>
          <div>
            <CommonButton
              type="text"
              block
              icon={<Plus size={14} color="var(--content-primary)" />}
              onClick={() => {
                onManualQuestionAdd();
              }}
              disabled={loading}
            >
              Add Question Manually
            </CommonButton>
          </div>
        </div>
      </ConfigProvider>
    </section>
  );
};
