import { Info } from '@phosphor-icons/react';
import { Flex, Tooltip } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

type InfoLabelType = {
  title: string;
  tooltipText?: string;
};
export default function InfoLabel({ title, tooltipText }: InfoLabelType) {
  return (
    <Flex align="center" gap={4}>
      <Paragraph className="mb-0 medium text-content-secondary">
        {title}
      </Paragraph>
      {tooltipText && (
        <Tooltip title={tooltipText}>
          <Info
            size={18}
            color="var(--content-primary)"
            className="cursor-pointer"
          />
        </Tooltip>
      )}
    </Flex>
  );
}
