import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import { Link } from 'react-router-dom';
import { FormThemeMode } from '../../__generated__/graphql';
import { ROUTES } from '../../common/constants';
import { getThemeClassName } from '../../common/utils';
import Logo from '../../components/common/Logo';

export default function MadeWithZinq({ mode }: { mode: FormThemeMode }) {
  return (
    <Link to={ROUTES.MAIN} target="_blank">
      <Flex className="made-with-zinq" align="center" gap={4}>
        <Paragraph
          className={`mb-0 text-meta medium ${getThemeClassName(mode)}`}
        >
          Made with
        </Paragraph>
        <Logo mode={mode} width={56} />
      </Flex>
    </Link>
  );
}
