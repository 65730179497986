import { DeleteOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
  AddressBook,
  Calendar,
  CalendarBlank,
  CheckSquare,
  Clock,
  CopySimple,
  CurrencyDollar,
  DotsSixVertical,
  EnvelopeSimpleOpen,
  FlagCheckered,
  Gear,
  Handshake,
  House,
  Image,
  LineSegment,
  Notepad,
  NumberSquareNine,
  Palette,
  Phone,
  Plus,
  Question,
  RadioButton,
  Star,
  Trash,
  UploadSimple,
  UserList,
} from '@phosphor-icons/react';
import {
  Col,
  Divider,
  Dropdown,
  Flex,
  List,
  Popconfirm,
  Row,
  Segmented,
  Tooltip,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import Paragraph from 'antd/es/typography/Paragraph';
import {
  capitalize,
  cloneDeep,
  concat,
  isEmpty,
  isEqual,
  isNil,
  lowerCase,
  omit,
  set,
  slice,
  startCase,
  toLower,
  upperCase,
} from 'lodash';
import { nanoid } from 'nanoid';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../AppContext';
import {
  FormDesign as FormDesignTypes,
  FormSetting,
  FormStructure,
  Form as FormTypes,
} from '../../../__generated__/graphql';
import { LargeTextIcon, ShortTextIcon } from '../../../assets/icons';
import {
  DEFAULT_AUTO_SAVE_TIME,
  DROPDOWN_LIST,
  REGEX,
  ROUTES,
  STATIC_FORM_FIELDS,
} from '../../../common/constants';
import {
  convertFormSchemaArrayToObj,
  convertFormSchemaObjToArray,
  handleGraphQlSuccess,
  processObjects,
} from '../../../common/utils';
import CommonButton from '../../../components/primitives/CommonButton';
import CommonTag from '../../../components/primitives/CommonTag';
import useQueryParams from '../../../hooks/useQueryParams';
import useRouter from '../../../hooks/useRouter';
import { AppActionType, AppContextType } from '../../../types/appContext.type';
import { ID } from '../../../types/common.type';
import { FormFieldType } from '../../auth/auth.types';
import {
  FormCreationTabs,
  FormFileType,
  FormSchemaType,
  InputChangeTypes,
  NestedInputChangeTypes,
  SubscriptionPlans,
} from '../../form-builder/model/form/form.types';
import {
  GENERATE_FORM_STRUCTURE,
  UPDATE_FORM_DESIGN,
  UPDATE_FORM_SETTING,
  UPDATE_FORM_STRUCTURE,
} from '../graphql/mutations';
import { GET_FORM } from '../graphql/queries';
import { DataTypes } from './editor.types';
import { AddQuestion } from './pages/AddQuestion';
import { EditEndPage } from './pages/EditEndPage';
import { EditFormField } from './pages/EditFormField';
import { EditWelcomePage } from './pages/EditWelcomePage';
import { FormDesign } from './pages/FormDesign';
import { FormSettings } from './pages/FormSettings';
import OverviewForm from './pages/OverviewForm';
import TemplateSetting from './pages/TemplateSetting';
import AIErrorForm from './utils/AIErrorForm';
import AIProcessingForm from './utils/AIProcessingForm';
import AddMenuItem from './utils/AddMenuItem';
import FormLoading from './utils/FormLoading';

const DraggableFormList = () => {
  const [currentIndex, setCurrentIndex] = useState<ID | null>();
  const { updateQueryParams, getQueryParam } = useQueryParams();
  const defaultActiveTab = getQueryParam('activeTab') as string;
  const formId = getQueryParam('id') as string;

  const [selectedItem, setSelectedItem] = useState<FormSchemaType | null>(null);
  // const { navigate } = useRouter();

  // refs to check for initial renders
  const isFirstStructure = useRef(true);
  const isFirstDesign = useRef(true);
  const isFirstSetting = useRef(true);

  const [items, setItems] = useState<FormSchemaType[]>([]);
  const [cloneItems, setCloneItems] = useState<FormSchemaType[]>([]);
  const [formDesign, setFormDesign] = useState<FormDesignTypes | null>();
  const [cloneFormDesign, setCloneFormDesign] =
    useState<FormDesignTypes | null>();
  const [formSettings, setFormSettings] = useState<FormSetting | null>();
  const [cloneFormSettings, setCloneFormSettings] =
    useState<FormSetting | null>();
  const addItemList = [
    {
      key: '0',
      label: <span>What would you like to add?</span>,
    },
    {
      key: 'ADD_QUESTION',
      label: (
        <AddMenuItem
          title="Question"
          description="Add a new question to the form."
          icon={
            <Question
              size={16}
              color="var(--content-quarternary)"
              weight="fill"
            />
          }
        />
      ),
    },
    {
      key: 'WELCOME_PAGE',
      label: (
        <AddMenuItem
          title="Welcome Page"
          description="Landing page for your form."
          icon={
            <House size={16} color="var(--content-quarternary)" weight="fill" />
          }
        />
      ),
      disabled:
        items?.findIndex(
          (item) => item.answer_type === FormFieldType.WELCOME_PAGE,
        ) !== -1,
    },
    {
      key: 'END_PAGE',
      label: (
        <AddMenuItem
          title="End Page"
          description="Last page after user submits the form."
          icon={
            <FlagCheckered
              size={16}
              color="var(--content-quarternary)"
              weight="fill"
            />
          }
        />
      ),
      disabled:
        items?.findIndex(
          (item) => item.answer_type === FormFieldType.END_PAGE,
        ) !== -1,
    },
  ];
  const [currentTabValue, setCurrentTabValue] = useState<string>();
  const [counter, setCounter] = useState<number>();
  const [data, setData] = useState<DataTypes>();
  const [form] = useForm();
  const { navigate } = useRouter();
  const [init, setInit] = useState<boolean>(false);
  const {
    state: { appState, currentUser },
    dispatch,
  } = useContext(AppContext) as AppContextType;

  const baseTabBarList = [
    {
      label: 'Form',
      value: FormCreationTabs.FORM,
      icon: <Notepad weight="fill" size={20} />,
    },
    {
      label: 'Design',
      value: FormCreationTabs.DESIGN,
      icon: <Palette weight="fill" size={20} />,
    },
    {
      label: 'Settings',
      value: FormCreationTabs.SETTINGS,
      icon: <Gear weight="fill" size={20} />,
    },
  ];

  const hasAlphaFeature =
    currentUser.subscriptionPlan?.features?.find(
      ({ key }: { key: string }) => key === SubscriptionPlans.ALPHA_FEATURE,
    )?.value === true;

  const baseTabMapping: Record<string, number> = {
    [FormCreationTabs.FORM]: 1,
    [FormCreationTabs.DESIGN]: 6,
    [FormCreationTabs.SETTINGS]: 7,
  };

  const tabBarList = hasAlphaFeature
    ? [
        ...baseTabBarList,
        {
          label: 'Template settings',
          value: FormCreationTabs.TEMPLATE_SETTING,
          icon: <Gear weight="fill" size={20} />,
        },
      ]
    : baseTabBarList;

  const tabMapping: Record<string, number> = hasAlphaFeature
    ? {
        ...baseTabMapping,
        [FormCreationTabs.TEMPLATE_SETTING]: 8,
      }
    : baseTabMapping;

  useEffect(() => {
    setCurrentTabValue(
      tabMapping?.[upperCase(defaultActiveTab)]
        ? upperCase(defaultActiveTab)
        : FormCreationTabs.FORM,
    );
  }, [defaultActiveTab]);

  useEffect(() => {
    setCounter(
      tabMapping?.[currentTabValue as FormCreationTabs] ??
        tabMapping?.[FormCreationTabs.FORM],
    );
  }, [currentTabValue]);

  const handleOverviewStructure = (
    data: FormTypes,
    isFormMeta: boolean = false,
  ) => {
    const clonedData = cloneDeep(
      data.structures?.[0]?.structure,
    ) as FormSchemaType[];

    // Convert form schema object to array [json => AI format]
    const updatedData = convertFormSchemaObjToArray(clonedData);
    setItems(updatedData);
    setCloneItems(cloneDeep(updatedData));
    setCurrentIndex(updatedData?.[0]?.question_id);
    setData({
      overview: data.structures?.[0]?.overview || '',
      prompt: data?.prompt || '',
    });

    // update design & settings if form meta available
    if (isFormMeta) {
      setFormDesign(data.designs?.[0]);
      setCloneFormDesign(cloneDeep(data.designs?.[0]));
      setFormSettings(data.settings?.[0]);
      setCloneFormSettings(cloneDeep(data.settings?.[0]));
    }

    if (data?.title) {
      dispatch({
        type: AppActionType.setFormSettings,
        data: {
          title: data?.title,
        },
      });
    }
    // isFirstStructure.current = false;
  };

  const [generateFormStructureMutate, { loading: generateLoading }] =
    useMutation(GENERATE_FORM_STRUCTURE, {
      fetchPolicy: 'network-only',
      variables: {
        data: {
          formId: formId as string,
        },
      },
      onCompleted: (res) => {
        const formResponse = res.generateFormStructure?.data;
        handleOverviewStructure(formResponse as FormTypes);
      },
      onError: () => {
        dispatch({
          type: AppActionType.setAppState,
          data: {
            formCreateError: true,
          },
        });
      },
    });

  const [fetchFormData, { loading: fetchLoading }] = useLazyQuery(GET_FORM, {
    fetchPolicy: 'network-only',
    variables: {
      where: {
        formId: formId as string,
      },
    },
    onCompleted: (res) => {
      const formResponse = res.form?.data;
      if (isEmpty(formResponse?.structures?.[0]?.structure)) {
        // update form designs & settings
        handleOverviewStructure(formResponse as FormTypes, true);
        // generate form
        generateFormStructureMutate();
      } else {
        handleOverviewStructure(formResponse as FormTypes, true);
      }
    },
    onError: () => {
      navigate(ROUTES.NOT_FOUND);
    },
  });

  const [updateStructureMutate, { loading: updateLoading }] = useMutation(
    UPDATE_FORM_STRUCTURE,
    {
      onError() {},
    },
  );

  const [updateDesignMutate] = useMutation(UPDATE_FORM_DESIGN, {
    onCompleted: (res) => {
      handleGraphQlSuccess(res.updateFormDesign?.message);
    },
    onError() {},
  });

  const [updateSettingMutate] = useMutation(UPDATE_FORM_SETTING, {
    onCompleted: (res) => {
      handleGraphQlSuccess(res.updateFormSetting?.message);
    },
    onError() {},
  });

  // const [regenerateStructureMutate, { loading: regenerateLoading }] =
  //   useMutation(REGENERATE_FORM_STRUCTURE, {
  //     onCompleted: (res) => {
  //       handleOverviewStructure(res.generateFormStructure?.data as Form);
  //     },
  //     onError() {},
  //   });

  const updateCurrentItem = () => {
    if (currentIndex) {
      // onActiveItemChange(items, currentIndex as string);
      dispatch({
        type: AppActionType.setFormItems,
        data: {
          items: items,
          activeId: currentIndex,
        },
      });
    }
  };

  useEffect(() => {
    dispatch({
      type: AppActionType.setAppState,
      data: {
        formFetchLoading: fetchLoading,
        formCreateLoading: generateLoading,
      },
    });
  }, [fetchLoading, generateLoading]);

  useEffect(() => {
    // eslint-disable-next-line no-console
    updateCurrentItem();
  }, [currentIndex, items]);

  const getFormData = async () => {
    // dispatch({
    //   type: AppActionType.setAppState,
    //   data: {
    //     formCreateLoading: true,
    //   },
    // });
    fetchFormData();
  };

  useEffect(() => {
    getFormData();
  }, []);

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: string) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData('text/plain'));
    const newItems = [...items];
    const [reorderedItem] = newItems.splice(dragIndex, 1);
    newItems.splice(dropIndex, 0, reorderedItem);
    setItems(newItems);
  };

  const handleNestedInputChange = useCallback(
    ({ key, field, value, nestedId }: NestedInputChangeTypes) => {
      setItems((prevContacts: FormSchemaType[]) => {
        const index = prevContacts.findIndex(
          (contact: FormSchemaType) => contact?.question_id === key,
        );
        if (index !== -1) {
          const newContacts = [...prevContacts];
          newContacts[index] = {
            ...newContacts[index],
            nested: newContacts?.[index]?.nested?.map((item: FormSchemaType) =>
              item.answer_store_key === nestedId
                ? { ...item, [field]: value }
                : item,
            ),
          };
          setSelectedItem(newContacts[index]);
          return newContacts;
        }
        return prevContacts;
      });
    },
    [],
  );

  const handleInputChange = useCallback(
    ({ id, path, value }: InputChangeTypes) => {
      setItems((prevContacts: FormSchemaType[]) => {
        const index = prevContacts.findIndex(
          (contact: FormSchemaType) => contact?.question_id === id,
        );
        if (index !== -1) {
          const newContacts = [...prevContacts];
          path.forEach((path, i) => {
            set(newContacts[index], path, value[i]);
          });
          return newContacts;
        }
        return prevContacts;
      });
    },
    [],
  );

  const modifyFormFieldType = (value: FormSchemaType, type: FormFieldType) => {
    let newObj: FormSchemaType | null = null;
    newObj = {
      question_id: value.question_id,
      answer_store_key: value.answer_store_key,
      question_description: value.question_description,
      question_text: value.question_text,
      question_title: value.question_title,
      answer_type: type,
      ai_guideline: '',
      is_required: value.is_required,
      showAllTags: value.showAllTags,
    };
    switch (type) {
      case FormFieldType.PHONE_NUMBER:
        newObj = {
          ...newObj,
          options: [],
          nested: STATIC_FORM_FIELDS.phone,
        };
        return newObj;
      case FormFieldType.NUMBER:
        return newObj;
      case FormFieldType.EMAIL:
        newObj = {
          ...newObj,
          config: {
            regex: REGEX.EMAIL?.toString(),
          },
        };
        return newObj;
      case FormFieldType.SELECT:
        newObj = {
          ...newObj,
          options: !isEmpty(value.options)
            ? value?.options
            : DROPDOWN_LIST.defaultFormOptionList,
        };
        return newObj;
      case FormFieldType.MULTI_SELECT:
        newObj = {
          ...newObj,
          options: !isEmpty(value.options)
            ? value?.options
            : DROPDOWN_LIST.defaultFormOptionList,
        };
        return newObj;
      case FormFieldType.TEXT:
        return newObj;
      case FormFieldType.TIME:
        return newObj;
      case FormFieldType.DATE:
        newObj = {
          ...newObj,
          config: {
            allow_past_date: true,
            allow_future_date: true,
          },
        };
        return newObj;
      case FormFieldType.LINEAR_SCALE:
        newObj = {
          ...newObj,
          config: {
            min_rating: 1,
            max_rating: 5,
            min_label: '',
            max_label: '',
          },
        };
        return newObj;
      case FormFieldType.LONG_TEXT:
        if (value?.config?.character_limit) {
          newObj = {
            ...newObj,
            config: {
              character_limit: value?.config?.character_limit,
            },
          };
        }
        return newObj;
      case FormFieldType.SHORT_TEXT:
        if (value?.config?.character_limit) {
          newObj = {
            ...newObj,
            config: {
              character_limit: value?.config?.character_limit,
            },
          };
        }
        return newObj;
      case FormFieldType.FILE:
        newObj = {
          ...newObj,
          config: {
            file_type: [FormFileType.DOCUMENT],
            max_file_count: 1,
            max_file_size: 2,
          },
        };
        return newObj;
      case FormFieldType.RATE:
        newObj = {
          ...newObj,
          config: {
            min_rating: 1,
            max_rating: 5,
          },
        };
        return newObj;
      case FormFieldType.ADDRESS:
        newObj = {
          ...newObj,
          options: [],
          nested: STATIC_FORM_FIELDS.address,
        };
        return newObj;
      case FormFieldType.CONTACT_INFO:
        newObj = {
          ...newObj,
          options: [],
          nested: STATIC_FORM_FIELDS.contactInfo,
        };
        return newObj;
    }
  };

  const handleFieldTypeChange = useCallback(
    (id: number | string, type: FormFieldType) => {
      setItems((prevContacts: FormSchemaType[]) => {
        const index = prevContacts.findIndex(
          (contact: FormSchemaType) => contact?.question_id === id,
        );
        if (index !== -1) {
          const newContacts = [...prevContacts];
          const updatedObj = modifyFormFieldType(newContacts[index], type);
          if (!isEmpty(updatedObj)) {
            newContacts[index] = updatedObj;
            setSelectedItem(newContacts[index]);
            setCurrentIndex(newContacts?.[index].question_id);
          }
          return newContacts;
        }
        return prevContacts;
      });
    },
    [],
  );

  const handleDelete = (key: ID) => {
    const remainingData = items?.filter(
      (element) => element.question_id !== key,
    );
    setItems(remainingData);
    setCurrentIndex(remainingData?.[remainingData.length - 1]?.question_id);
    setCounter(1);
  };

  const handleDuplicateItem = (index: number) => {
    setItems((prevItems: FormSchemaType[]) => {
      const newItems = [...prevItems];
      const title = `Copy of ${newItems[index].question_title}`;

      const itemToDuplicate = {
        ...newItems[index],
        question_title: title,
        question_id: nanoid(),
        answer_store_key: '',
      };

      setSelectedItem(itemToDuplicate);
      setCurrentIndex(itemToDuplicate.question_id as string);
      setCounter(2);
      newItems.splice(index + 1, 0, itemToDuplicate);
      return newItems;
    });
  };

  const handleAddItem = () => {
    const title = 'Untitled';
    const questionText = 'Untitled';

    const obj = {
      ...STATIC_FORM_FIELDS.defaultQuestion?.[0],
      question_title: title,
      question_text: questionText,
      question_id: nanoid(),
    };

    setSelectedItem(obj);
    setCurrentIndex(obj.question_id as string);
    const index = items?.findIndex(
      (val) => val.answer_type === FormFieldType.END_PAGE,
    );
    if (index !== -1) {
      // add new item before end page
      const updatedArray = concat(
        slice(items, 0, items?.length - 1),
        obj,
        slice(items, items?.length - 1),
      );
      setItems(updatedArray);
    } else {
      setItems([...items, obj]);
    }
    setCounter(2);
  };

  const handleAddWelcomePage = () => {
    setItems((prevItems: FormSchemaType[]) => {
      const newItems = [...prevItems];

      const itemToAdd = {
        ...STATIC_FORM_FIELDS.defaultWelcomePage?.[0],
        question_id: nanoid(),
      };

      setSelectedItem(itemToAdd);
      setCurrentIndex(itemToAdd.question_id as string);
      setCounter(4);
      newItems.splice(0, 0, itemToAdd);
      return newItems;
    });
  };

  const handleAddEndPage = () => {
    setItems((prevItems: FormSchemaType[]) => {
      const newItems = [...prevItems];

      const itemToAdd = {
        ...STATIC_FORM_FIELDS.defaultEndPage?.[0],
        question_id: nanoid(),
      };

      setSelectedItem(itemToAdd);
      setCurrentIndex(itemToAdd.question_id as string);
      setCounter(5);
      newItems.splice(newItems?.length, 0, itemToAdd);
      return newItems;
    });
  };

  // const validateStructureFields = (structure: FormSchemaType[]) => {
  //   // eslint-disable-next-line no-console
  //   console.log(
  //     structure.filter((item) => item?.question_title?.trim() === ''),
  //   );
  //   return (
  //     filter(structure, (item) => item?.question_title?.trim() === '').length >
  //     0
  //   );
  // };

  const handleSaveFormStructure = () => {
    const updatedItems = convertFormSchemaArrayToObj(
      processObjects(cloneDeep(items)),
    );
    updateStructureMutate({
      variables: {
        where: {
          formId,
        },
        data: {
          structure: updatedItems,
        },
      },
      onCompleted: (res) => {
        const clonedData = cloneDeep(
          res.updateFormStructure?.data?.structures?.[0]?.structure,
        ) as FormSchemaType[];
        const updatedData = convertFormSchemaObjToArray(clonedData);
        setCloneItems(cloneDeep(updatedData));
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            ...res.updateFormStructure?.data,
          },
        });
      },
    });
  };

  const handleSaveFormDesign = () => {
    updateDesignMutate({
      variables: {
        where: {
          formId,
        },
        data: omit(formDesign, ['__typename']),
      },
      onCompleted: (res) => {
        setCloneFormDesign(res.updateFormDesign?.data as FormDesignTypes);
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            ...res.updateFormDesign?.data,
          },
        });
      },
    });
  };

  const handleSaveFormSettings = () => {
    updateSettingMutate({
      variables: {
        where: {
          formId,
        },
        data: omit(formSettings, ['__typename']),
      },
      onCompleted: (res) => {
        setCloneFormSettings(res.updateFormSetting?.data as FormSetting);
        dispatch({
          type: AppActionType.setFormSettings,
          data: {
            ...res.updateFormSetting?.data,
          },
        });
      },
    });
  };

  // Auto save on items change
  useEffect(() => {
    if (isFirstStructure.current) {
      isFirstStructure.current = false;
    } else {
      const timer = setTimeout(() => {
        if (!isEqual(items, cloneItems)) {
          handleSaveFormStructure();
        }
      }, DEFAULT_AUTO_SAVE_TIME);
      return () => clearTimeout(timer);
    }
  }, [items]);

  // // Auto save on items change
  useEffect(() => {
    if (isFirstDesign.current) {
      isFirstDesign.current = false;
    } else {
      const timer = setTimeout(() => {
        if (!isEqual(formDesign, cloneFormDesign)) {
          handleSaveFormDesign();
        }
      }, DEFAULT_AUTO_SAVE_TIME);

      return () => clearTimeout(timer);
    }
  }, [formDesign]);

  // // Auto save on items change
  useEffect(() => {
    if (isFirstSetting.current) {
      isFirstSetting.current = false;
    } else {
      const timer = setTimeout(() => {
        if (!isEqual(formSettings, cloneFormSettings)) {
          handleSaveFormSettings();
        }
      }, DEFAULT_AUTO_SAVE_TIME);

      return () => clearTimeout(timer);
    }
  }, [formSettings]);

  const selectOptionMapping: { [key: string]: React.ReactNode } = {
    [FormFieldType.SHORT_TEXT]: <ShortTextIcon />,
    [FormFieldType.LONG_TEXT]: <LargeTextIcon />,
    [FormFieldType.SELECT]: (
      <RadioButton size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.MULTI_SELECT]: (
      <CheckSquare size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.EMAIL]: (
      <EnvelopeSimpleOpen
        size={16}
        weight="fill"
        color="var(--content-primary)"
      />
    ),
    [FormFieldType.NUMBER]: (
      <NumberSquareNine
        size={16}
        weight="fill"
        color="var(--content-primary)"
      />
    ),
    [FormFieldType.RATE]: (
      <Star size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.LINEAR_SCALE]: (
      <LineSegment size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.FILE]: (
      <UploadSimple size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.DATE]: (
      <CalendarBlank size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.TIME]: (
      <Clock size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.PHONE_NUMBER]: (
      <Phone size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.CONTACT_INFO]: (
      <AddressBook size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.ADDRESS]: (
      <UserList size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.PAYMENT]: (
      <CurrencyDollar size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.CALENDAR]: (
      <Handshake size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.DATE_RANGE]: (
      <Calendar size={16} weight="fill" color="var(--content-primary)" />
    ),
    [FormFieldType.PICTURE_CHOICE]: (
      <Image size={16} weight="fill" color="var(--content-primary)" />
    ),
  };

  const renderQuestionType = (type: FormFieldType) => {
    return (
      <CommonTag
        icon={selectOptionMapping?.[type]}
        color="default"
        bordered={false}
        className="medium"
      >
        {startCase(toLower(type))}
      </CommonTag>
    );
  };

  const renderOptions = (item?: FormSchemaType) => {
    switch (item?.answer_type) {
      case FormFieldType.SHORT_TEXT:
      case FormFieldType.LONG_TEXT:
        // eslint-disable-next-line no-case-declarations
        const charLimit = item?.config?.character_limit;
        if (!isNil(charLimit)) {
          return (
            <>
              <Divider type="vertical" className="m-0" />
              <Flex gap={8} wrap>
                <CommonTag color="warning">{`Max: ${charLimit}`}</CommonTag>
              </Flex>
            </>
          );
        }
        return null;
      case FormFieldType.MULTI_SELECT:
      case FormFieldType.SELECT:
        return (
          <>
            {item?.options && item?.options?.length > 0 && (
              <Divider type="vertical" className="m-0" />
            )}
            <Flex gap={8} wrap>
              {item?.options
                ?.map((option, index) => (
                  <>
                    <CommonTag key={index} color="warning">
                      {option as string}
                    </CommonTag>
                  </>
                ))
                ?.splice(0, !item?.showAllTags ? 3 : item?.options?.length)}
              {item?.options &&
                item?.options?.length > 3 &&
                !item?.showAllTags && (
                  <Tooltip title="Show All">
                    <CommonTag
                      color="warning"
                      onClick={(e) => {
                        e?.stopPropagation();
                        handleInputChange({
                          id: item?.question_id as string,
                          path: ['showAllTags'],
                          value: [true],
                        });
                      }}
                    >
                      {`+${item?.options?.length - 3}`}
                    </CommonTag>
                  </Tooltip>
                )}
            </Flex>
          </>
        );
      case FormFieldType.PHONE_NUMBER:
        return (
          <>
            <Divider type="vertical" className="m-0" />
            <Flex gap={8} wrap>
              <CommonTag color="warning">Country Code</CommonTag>
              <CommonTag color="warning">Phone</CommonTag>
            </Flex>
          </>
        );
      case FormFieldType.CONTACT_INFO:
      case FormFieldType.ADDRESS:
        // eslint-disable-next-line no-case-declarations
        const hiddenItemCount =
          item?.nested?.filter((val) => !val.hidden)?.length || 0;
        return (
          <>
            {item?.nested && item?.nested?.length > 0 && (
              <Divider type="vertical" className="m-0" />
            )}
            <Flex gap={8} wrap>
              {item?.nested
                ?.filter((val) => !val.hidden)
                ?.map((item, index) => (
                  <>
                    <CommonTag key={index} color="warning">
                      {item?.name as string}
                    </CommonTag>
                  </>
                ))
                ?.splice(0, !item?.showAllTags ? 3 : item?.nested?.length)}
              {item?.nested &&
                hiddenItemCount > 3 &&
                item?.nested?.length > 3 &&
                !item?.showAllTags && (
                  <Tooltip title="Show All">
                    <CommonTag
                      color="warning"
                      onClick={(e) => {
                        e?.stopPropagation();
                        handleInputChange({
                          id: item?.question_id as string,
                          path: ['showAllTags'],
                          value: [true],
                        });
                      }}
                    >
                      {`+${hiddenItemCount - 3}`}
                    </CommonTag>
                  </Tooltip>
                )}
            </Flex>
          </>
        );
      case FormFieldType.FILE:
        // eslint-disable-next-line no-case-declarations
        const fileCount = item?.config?.max_file_count || 0;
        // eslint-disable-next-line no-case-declarations
        const fileSize = item?.config?.max_file_size || 0;
        // eslint-disable-next-line no-case-declarations
        const fileType = item?.config?.file_type;
        return (
          <>
            {(!isNil(fileCount) || !isNil(fileSize) || !isNil(fileType)) && (
              <Divider type="vertical" className="m-0" />
            )}
            <Flex gap={8} wrap>
              <CommonTag color="warning">{`Max: ${fileCount}`}</CommonTag>
              <CommonTag color="warning">{`Size: ${fileSize}MB`}</CommonTag>
              <CommonTag color="warning">
                {`Type: ${fileType?.map((item) => capitalize(item)).join(', ') || '-'}`}
              </CommonTag>
            </Flex>
          </>
        );
      case FormFieldType.DATE:
        // eslint-disable-next-line no-case-declarations
        const isPastAllowed = item?.config?.allow_past_date;
        // eslint-disable-next-line no-case-declarations
        const isFutureAllowed = item?.config?.allow_future_date;

        return (
          <>
            {(isPastAllowed || isFutureAllowed) && (
              <Divider type="vertical" className="m-0" />
            )}
            <Flex gap={8} wrap>
              {isPastAllowed && (
                <CommonTag color="warning">{`Past date allowed`}</CommonTag>
              )}
              {isFutureAllowed && (
                <CommonTag color="warning">{`Future date allowed`}</CommonTag>
              )}
            </Flex>
          </>
        );
      case FormFieldType.NUMBER:
        // eslint-disable-next-line no-case-declarations
        const min = item?.config?.min_number;
        // eslint-disable-next-line no-case-declarations
        const max = item?.config?.max_number;
        if (min || max) {
          return (
            <>
              <Divider type="vertical" className="m-0" />
              <Flex gap={8} wrap>
                {!isNil(min) && (
                  <CommonTag color="warning">{`Min: ${min}`}</CommonTag>
                )}
                {!isNil(max) && (
                  <CommonTag color="warning">{`Max: ${max}`}</CommonTag>
                )}
              </Flex>
            </>
          );
        }
        return null;
      case FormFieldType.RATE:
      case FormFieldType.LINEAR_SCALE:
        // eslint-disable-next-line no-case-declarations
        const minRating = item?.config?.min_rating;
        // eslint-disable-next-line no-case-declarations
        const maxRating = item?.config?.max_rating;
        if (!isNil(minRating) && !isNil(maxRating)) {
          return (
            <>
              <Divider type="vertical" className="m-0" />
              <Flex gap={8} wrap>
                <CommonTag color="warning">
                  {`${minRating} to ${maxRating}`}
                </CommonTag>
              </Flex>
            </>
          );
        }
        return null;
      default:
        return null;
    }
  };

  const renderFormOverview = (item: FormSchemaType, index: number) => {
    const title = item?.question_title;
    const type = item?.answer_type;
    const allowDelete = items?.length > 1;

    if (type === FormFieldType.WELCOME_PAGE) {
      return (
        <div
          className="form-item-overview"
          tabIndex={index + 1}
          onClick={() => {
            setCurrentIndex(item?.question_id);
            setSelectedItem(item);
            setCounter(4);
          }}
        >
          <Flex vertical gap={6} align="flex-start">
            <Flex gap={2}>
              <Paragraph className="mb-0 text-content-primary text-base semi-bold text-content-primary">
                Welcome Page
              </Paragraph>
            </Flex>
            <Flex vertical align="flex-start">
              <Paragraph className="text-content-secondary mb-0">
                This page will be showed when someone opens the form link.
              </Paragraph>
            </Flex>
          </Flex>
          {allowDelete && (
            <div className="card-controls">
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure, you want to delete?"
                  okText="Yes"
                  className="hover-show"
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    handleDelete(item?.question_id as ID);
                  }}
                  onCancel={(e) => e?.stopPropagation()}
                  placement="right"
                  okButtonProps={{
                    block: true,
                    icon: <DeleteOutlined />,
                    danger: true,
                  }}
                  cancelButtonProps={{ block: true }}
                  icon={null}
                >
                  <CommonButton
                    size="small"
                    type="text"
                    shape="circle"
                    className="bg-surface-tertiary"
                    shadow={false}
                    onClick={(e) => e.stopPropagation()}
                    icon={<Trash color="var(--danger-on-surface)" size={16} />}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          )}
        </div>
      );
    }
    if (type === FormFieldType.END_PAGE) {
      return (
        <div
          className="form-item-overview"
          tabIndex={index + 1}
          onClick={() => {
            setCurrentIndex(item.question_id as string);
            setSelectedItem(item);
            setCounter(5);
          }}
        >
          <Flex vertical gap={6} align="flex-start">
            <Flex gap={2}>
              <Paragraph className="mb-0 text-content-primary text-base semi-bold text-content-primary">
                End Page
              </Paragraph>
            </Flex>
            <Flex vertical align="flex-start">
              <Paragraph className="text-content-secondary mb-0">
                This page will be showed when someone completes the form.
              </Paragraph>
            </Flex>
          </Flex>
          {allowDelete && (
            <div className="card-controls">
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure, you want to delete?"
                  okText="Yes"
                  className="hover-show"
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    handleDelete(item.question_id as string);
                  }}
                  onCancel={(e) => e?.stopPropagation()}
                  placement="right"
                  okButtonProps={{
                    block: true,
                    icon: <DeleteOutlined />,
                    danger: true,
                  }}
                  cancelButtonProps={{ block: true }}
                  icon={null}
                >
                  <CommonButton
                    size="small"
                    type="text"
                    shape="circle"
                    className="bg-surface-tertiary"
                    shadow={false}
                    onClick={(e) => e.stopPropagation()}
                    icon={<Trash color="var(--danger-on-surface)" size={16} />}
                  />
                </Popconfirm>
              </Tooltip>
            </div>
          )}
        </div>
      );
    }
    return (
      <div
        className="form-item-overview"
        tabIndex={index + 1}
        onClick={() => {
          setCurrentIndex(item.question_id as string);
          setSelectedItem(item);
          setCounter(2);
        }}
      >
        <Flex vertical gap={6} align="flex-start">
          <Flex gap={2}>
            <Paragraph
              ellipsis={{
                rows: 2,
              }}
              className="mb-0 text-content-primary text-base semi-bold text-content-primary"
            >
              {title || '(Untitled)'}
              {item?.is_required && (
                <span className="ml-2 text-danger-on-surface">*</span>
              )}
            </Paragraph>
          </Flex>
          <Flex vertical align="flex-start">
            <Paragraph className="text-content-secondary">
              {item?.question_text}
            </Paragraph>
            <Flex align="center" gap={8} wrap>
              {item?.answer_type &&
                renderQuestionType(item.answer_type as FormFieldType)}
              {renderOptions(item)}
            </Flex>
          </Flex>
        </Flex>
        <div className="card-controls">
          <Flex gap={8}>
            <Tooltip title="Duplicate">
              <CommonButton
                type="text"
                size="small"
                shape="circle"
                className="bg-surface-tertiary"
                shadow={false}
                onClick={(e) => {
                  e?.stopPropagation();
                  handleDuplicateItem(index);
                }}
                icon={
                  <CopySimple
                    color="var(--content-primary)"
                    weight="fill"
                    size={16}
                  />
                }
              ></CommonButton>
            </Tooltip>
            {allowDelete && (
              <Tooltip title="Delete">
                <Popconfirm
                  title="Are you sure, you want to delete?"
                  okText="Yes"
                  className="hover-show"
                  onConfirm={(e) => {
                    e?.stopPropagation();
                    handleDelete(item.question_id as string);
                  }}
                  onCancel={(e) => e?.stopPropagation()}
                  placement="right"
                  okButtonProps={{
                    block: true,
                    icon: <DeleteOutlined />,
                    danger: true,
                  }}
                  cancelButtonProps={{ block: true }}
                  icon={null}
                >
                  <CommonButton
                    size="small"
                    type="text"
                    shape="circle"
                    className="bg-surface-tertiary"
                    shadow={false}
                    onClick={(e) => e.stopPropagation()}
                    icon={<Trash color="var(--danger-on-surface)" size={16} />}
                  />
                </Popconfirm>
              </Tooltip>
            )}
          </Flex>
        </div>
      </div>
    );
  };

  const renderList = (item: FormSchemaType, index: number) => {
    const type = item.answer_type;
    switch (type) {
      case FormFieldType.WELCOME_PAGE:
        return (
          <List.Item key={item.question_id}>
            <Row
              className="custom-list-renderer w-full"
              gutter={[12, 12]}
              align="middle"
              justify={'start'}
            >
              <Col lg={4} xl={3}>
                <Flex
                  vertical
                  className="p-8 bg-surface-secondary border-sm w-max-content mx-auto"
                  justify="space-between"
                  align="center"
                  gap={4}
                >
                  <House
                    size={16}
                    color="var(--content-quarternary)"
                    weight="fill"
                  />
                </Flex>
              </Col>
              <Col lg={20} xl={21}>
                <div>{renderFormOverview(item, index)}</div>
              </Col>
            </Row>
          </List.Item>
        );
      case FormFieldType.END_PAGE:
        return (
          <List.Item key={item.question_id}>
            <Row
              className="custom-list-renderer w-full"
              gutter={[12, 12]}
              align="middle"
              justify={'start'}
            >
              <Col lg={4} xl={3}>
                <Flex
                  vertical
                  className="p-8 bg-surface-secondary border-sm w-max-content mx-auto"
                  justify="space-between"
                  align="center"
                  gap={4}
                >
                  <FlagCheckered
                    size={16}
                    color="var(--content-quarternary)"
                    weight="fill"
                  />
                </Flex>
              </Col>
              <Col lg={20} xl={21}>
                <div>{renderFormOverview(item, index)}</div>
              </Col>
            </Row>
          </List.Item>
        );

      default:
        return (
          <List.Item
            key={item.question_id}
            draggable
            onDragStart={(e) => onDragStart(e, index.toString())}
            onDragOver={onDragOver}
            onDrop={(e) => onDrop(e, index)}
          >
            <Row
              className="custom-list-renderer cursor-move w-full"
              gutter={[12, 12]}
              align="middle"
              justify={'start'}
            >
              <Col lg={4} xl={3}>
                <Flex
                  vertical
                  className="p-8 bg-surface-secondary border-sm w-max-content mx-auto"
                  justify="space-between"
                  align="center"
                  gap={4}
                >
                  <DotsSixVertical size={16} color="var(--content-tertiary)" />
                  <Paragraph className="text-base mb-0 semi-bold text-content-quarternary">
                    {index + 1}
                  </Paragraph>
                </Flex>
              </Col>
              <Col lg={20} xl={21}>
                <div>{renderFormOverview(item, index)}</div>
              </Col>
            </Row>
          </List.Item>
        );
    }
  };

  const renderFormList = () => {
    switch (true) {
      case appState?.formCreateLoading:
        return <AIProcessingForm />;
      case appState?.formFetchLoading:
        return <FormLoading />;
      case appState?.formCreateError:
        return (
          <AIErrorForm
            onRetry={() => {
              generateFormStructureMutate();
            }}
            onAddQuestion={() => {
              dispatch({
                type: AppActionType.setAppState,
                data: {
                  formCreateError: false,
                },
              });
              setCounter(3);
            }}
          />
        );

      default:
        return (
          <div className="scroll-wrapper">
            {/* Different screens here */}
            {counter === 1 && (
              <div className="scroll-wrapper-body">
                <OverviewForm
                  overviewText={data?.overview}
                  promptText={data?.prompt}
                  onFinish={(res: FormTypes) => {
                    handleOverviewStructure(res as FormTypes);
                  }}
                />
                <List
                  dataSource={items}
                  className="draggable-form-list"
                  renderItem={(item, index: number) => renderList(item, index)}
                />
                <Flex gap={8} className="form-buttons">
                  <Dropdown
                    menu={{
                      items: addItemList,
                      onClick: ({ key }) => {
                        switch (key) {
                          case 'ADD_QUESTION':
                            setCounter(3);
                            break;
                          case 'WELCOME_PAGE':
                            handleAddWelcomePage();
                            break;
                          case 'END_PAGE':
                            handleAddEndPage();
                            break;
                          default:
                            setCounter(Number(key));
                            break;
                        }
                      },
                    }}
                    placement="top"
                    trigger={['click']}
                    getPopupContainer={() =>
                      document.getElementById(
                        'add-item-container',
                      ) as HTMLElement
                    }
                  >
                    <CommonButton
                      type="primary"
                      icon={<Plus color="var(--neutrals)" size={16} />}
                      // onClick={handleAddItem}
                      // onClick={() => {
                      //   setCounter(3);
                      // }}
                      disabled={updateLoading}
                      size="large"
                    >
                      Add
                    </CommonButton>
                  </Dropdown>
                </Flex>
                <div id="add-item-container"></div>
              </div>
            )}
            {counter === 2 && selectedItem && (
              <EditFormField
                item={selectedItem}
                itemList={items}
                onBack={() => setCounter(1)}
                optionsMapping={selectOptionMapping}
                onInputChange={({ id, path, value }: InputChangeTypes) => {
                  handleInputChange({ id, path, value });
                }}
                onNestedFieldChange={({
                  key,
                  field,
                  value,
                  nestedId,
                }: NestedInputChangeTypes) => {
                  handleNestedInputChange({ key, field, value, nestedId });
                }}
                onDelete={(value) => {
                  handleDelete(value);
                }}
                onFieldTypeChange={(id, value) =>
                  handleFieldTypeChange(id, value)
                }
              />
            )}
            {counter === 3 && formId && (
              <AddQuestion
                onBack={() => setCounter(1)}
                onManualQuestionAdd={() => {
                  handleAddItem();
                }}
                formId={formId}
                onAIQuestionAdd={(res: FormStructure) => {
                  handleOverviewStructure({
                    structures: [{ structure: res.structure }],
                  });
                  setCounter(1);
                }}
              />
            )}
            {counter === 4 && selectedItem && (
              <EditWelcomePage
                item={selectedItem}
                onBack={() => setCounter(1)}
                onChange={({ id, path, value }: InputChangeTypes) => {
                  handleInputChange({ id, path, value });
                }}
              />
            )}
            {counter === 5 && selectedItem && (
              <EditEndPage
                item={selectedItem}
                onBack={() => setCounter(1)}
                onChange={({ id, path, value }: InputChangeTypes) => {
                  handleInputChange({ id, path, value });
                }}
              />
            )}
            {counter === 6 && formDesign && (
              <FormDesign
                formId={formId}
                onBack={() => setCounter(1)}
                formDesign={formDesign}
                onChange={(val: FormDesignTypes) => {
                  setFormDesign({
                    ...formDesign,
                    ...val,
                  });
                }}
              />
            )}
            {counter === 7 && formSettings && (
              <FormSettings
                onBack={() => setCounter(1)}
                formSettings={formSettings}
                onChange={(val: FormSetting) => {
                  setFormSettings({ ...formSettings, ...val });
                }}
              />
            )}

            {counter === 8 && (
              <TemplateSetting
                onBack={() => {
                  setCounter(1);
                  setCurrentTabValue(FormCreationTabs.FORM);
                }}
              />
            )}
          </div>
        );
    }
  };

  return (
    <section className="zinq-form-builder-config">
      <div className="flex justify-center mx-auto flex-column">
        <Segmented
          value={currentTabValue}
          className="app-segments mb-16"
          onChange={(value) => {
            updateQueryParams({ activeTab: lowerCase(value) });
            setCurrentTabValue(value);
            setCounter(tabMapping?.[value as FormCreationTabs]);
          }}
          options={tabBarList}
          disabled={fetchLoading || generateLoading}
        />
        <div className="background-wrapper">{renderFormList()}</div>
      </div>
    </section>
  );
};

export default DraggableFormList;
