import {
  ArrowsClockwise,
  Question,
  WarningOctagon,
} from '@phosphor-icons/react';
import { Flex, Result } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import CommonButton from '../../../../components/primitives/CommonButton';

type AIErrorFormType = {
  onRetry: () => void;
  onAddQuestion: () => void;
};

export default function AIErrorForm({
  onRetry,
  onAddQuestion,
}: AIErrorFormType) {
  return (
    <div className="scroll-wrapper flex justify-center items-center">
      <Result
        status="error"
        icon={<WarningOctagon color="var(--danger-on-surface)" size={56} />}
        title={
          <Title level={3} className="font-secondary medium">
            Oops! There was some error in creating questions
          </Title>
        }
        subTitle={
          <Paragraph className="mb-0 text-content-secondary">
            No worries, you can still add questions manually or try again with
            the same prompt
          </Paragraph>
        }
        extra={
          <Flex gap={8} wrap justify="center" align="center">
            <CommonButton
              key="add-question"
              type="text"
              onClick={() => {
                onAddQuestion();
              }}
              icon={
                <Question
                  size={16}
                  color="var(--content-primary)"
                  weight="fill"
                />
              }
            >
              Add question
            </CommonButton>
            <CommonButton
              type="primary"
              key="retry"
              icon={
                <ArrowsClockwise
                  size={16}
                  color="var(--content-inverse-primary)"
                  weight="fill"
                />
              }
              onClick={() => {
                onRetry();
              }}
            >
              Retry
            </CommonButton>
          </Flex>
        }
      ></Result>
    </div>
  );
}
