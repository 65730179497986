import { ConfigProvider, Tag, TagProps } from 'antd';

export default function CommonTag({ children, ...restProps }: TagProps) {
  return (
    <ConfigProvider
      theme={{
        token: {
          fontSize: 16,
          borderRadiusSM: 8,
        },
        components: {
          Tag: {
            colorText: 'var(--content-secondary)',
            defaultBg: 'var(--surface-tertiary)',
            colorWarning: 'var(--content-secondary)',
            colorWarningBg: 'var(--surface-primary)',
            colorWarningBorder: 'var(--border-primary)',
          },
        },
      }}
    >
      <Tag {...restProps}>{children}</Tag>
    </ConfigProvider>
  );
}
