import { gql } from '../../../__generated__';

export const CREATE_FORM = gql(`
mutation CreateForm($data: CreateFormInput!) {
  createForm(data: $data) {
    data {
      id
    }
  }
}`);

export const ADD_FORM_FIELDS = gql(`
mutation AddFormFields($where: FormUniqueInput!, $data: AddFormFieldInput!) {
  addFormFields(where: $where, data: $data) {
    data {
      overview
      structure
    }
  }
}`);

export const GENERATE_FORM_STRUCTURE = gql(`
  mutation GenerateFormStructure($data: FormUniqueInput!) {
  generateFormStructure(data: $data) {
    data {
      id
      status
      isUpdated
      title
      previewKey
      structures {
        overview
        structure
      }
      designs {
        themeId
        themeMode
        themeVariationId
      }
      settings {
        tone
        generativeQuestion
        finalPreview
        notifyOn
        notifyAtDay
        notifyAtSubmission
        closingMessage
      }
    }
  }
}`);

export const UPDATE_FORM_STRUCTURE = gql(`
mutation UpdateFormStructure($where: FormUniqueInput!, $data: UpdateFormStructureInput!) {
  updateFormStructure(where: $where, data: $data) {
    data {
      id
      status
      isUpdated
      previewKey
      structures {
        overview
        structure
      }
      designs {
        themeId
        themeMode
        themeVariationId
      }
      settings {
        tone
        generativeQuestion
        finalPreview
        notifyOn
        notifyAtDay
        notifyAtSubmission
        closingMessage
      }
    }
  }
}`);

export const UPDATE_FORM_DESIGN = gql(`
mutation UpdateFormDesign($where: FormUniqueInput!, $data: UpdateFormDesignInput!) {
  updateFormDesign(where: $where, data: $data) {
    message
    data {
      previewKey
      status
      isUpdated
    }
  }
}`);

export const UPDATE_FORM_SETTING = gql(`
mutation UpdateFormSetting($where: FormUniqueInput!, $data: UpdateFormSettingInput!) {
  updateFormSetting(where: $where, data: $data) {
    message
    data {
      previewKey
      status
      isUpdated
    }
  }
}`);

export const START_CONVERSATIONS_SUBMISSION = gql(`
mutation StartConversationSubmission($data: StartConversationSubmissionInput!) {
  startConversationSubmission(data: $data) {
    data {
      configs
      id
      aiResponse
      isCompleted
      submissionId
    }
  }
}`);

export const ANSWER_AND_NEXT = gql(`
 mutation AnswerAndNext($where: TranscriptUniqueInput!, $data: AnswerAndNextData!) {
  answerAndNext(where: $where, data: $data) {
    data {
      aiResponse
      id
      configs
      isCompleted
      submissionId
    }
  }
}`);

export const PUBLISH_FORM = gql(`
 mutation PublishForm($where: FormUniqueInput!) {
  publishForm(where: $where) {
    message
    data {
      previewKey
      status
      isUpdated
    }
  }
}`);

export const UPDATE_FORM_TITLE = gql(`
  mutation UpdateForm($where: FormUniqueInput!, $data: UpdateFormInput!) {
  updateForm(where: $where, data: $data) {
    message
    data {
      title
    }
  }
}`);

export const UPDATE_FORM_SHARE = gql(`
  mutation UpdateFormShare($data: FormShareUpdateInput!, $where: FormShareUniqueInput!) {
  updateFormShare(data: $data, where: $where) {
    data {
      id
    }
  }
}`);

export const UNPUBLISH_FORM = gql(`
mutation UnpublishForm($where: FormUniqueInput!, $data: UnpublishFormData) {
  unpublishForm(where: $where, data: $data) {
    data {
      status
    }
    message
  }
}`);

export const USE_FORM_TEMPLATE = gql(`
mutation UseFormTemplate($data: UserFormTemplateInput!) {
  useFormTemplate(data: $data) {
    data {
      id
    }
  }
}`);
