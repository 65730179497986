import { Flex } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import PremiumLogo from '../../../../components/common/PremiumLogo';

export default function PremiumLabel({ text }: { text: string }) {
  return (
    <Flex justify="center" align="center" gap={6}>
      <Paragraph className="mb-0">{text}</Paragraph>
      <PremiumLogo />
    </Flex>
  );
}
