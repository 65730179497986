import React, { useEffect, useState } from 'react';
import { convertJSONToFormItemObject } from '../../../../common/utils';
import { FormRenderer } from '../../renderer/FormRenderer';
import { Form } from './Form';
import { TextField } from './fields/TextField';
import { TimeField } from './fields/TimeField';

const aiGeneratedData = [
  {
    question: 'Give me your phone',
    type: 'PHONE_NUMBER',
    required: false,
    placeholder: 'Some placeholder',
    config: {
      uiStyle: 'PHONE_NUMBER',
      nested: {
        countryCode: {
          uiStyle: 'COUNTRY_CODE',
        },
        number: {
          uiStyle: 'NUMBER',
        },
      },
    },
  },
  {
    question: 'Contact Info',
    type: 'CONTACT_INFO',
    required: false,
    nested: [
      {
        // id: "5fas4e-f5asdf0-dfa54d-4sd54gs",
        id: 'firstName',
        question: 'First Name',
        type: 'TEXT',
        required: true,
        placeholder: 'Some placeholder',
        options: [],
        validations: {
          max: 100,
          min: 10,
          required: true,
        },
        config: {
          type: 'TEXT', // LONG_TEXT
        },
      },
      {
        id: 'lastName',
        question: 'Last Name',
        type: 'TEXT',
        required: true,
        placeholder: 'Some placeholder',
        options: [],
        validations: {
          max: 100,
          min: 10,
          required: true,
        },
        config: {
          type: 'TEXT', // LONG_TEXT
        },
      },
      {
        id: 'email',
        question: 'Give me your email?',
        type: 'EMAIL',
        required: false,
        placeholder: 'Some placeholder',
        options: [],
        validations: {
          required: true,
          regex: /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/,
        },
        config: {
          type: 'EMAIL',
        },
      },
      {
        id: 'company',
        question: 'Company',
        type: 'TEXT',
        required: true,
        placeholder: 'Some placeholder',
        options: [],
        validations: {
          max: 100,
          min: 10,
          required: true,
        },
        config: {
          type: 'TEXT', // LONG_TEXT
        },
      },
      {
        id: 'phoneNumber',
        question: 'Give me your phone',
        type: 'PHONE_NUMBER',
        required: false,
        placeholder: 'Some placeholder',
        nested: [
          {
            id: 'countryCode',
            question: 'country code.',
            type: 'COUNTRY_CODE',
            required: true,
            placeholder: 'Some placeholder',
            options: [],
            validations: {
              required: true,
            },
          },
          {
            id: 'phoneNumber',
            key: 'phoneNumber',
            question: 'Number.',
            type: 'NUMBER',
            required: true,
            placeholder: 'Some placeholder',
            options: [],
            validations: {
              required: true,
            },
          },
        ],
      },
    ],
    placeholder: 'Some placeholder',
    config: {
      uiStyle: 'CONTACT_INFO',
      nested: {
        firstName: {
          hidable: true,
        },
        lastName: {
          hidable: true,
        },
        phoneNumber: {
          hidable: false,
        },
        email: {
          hidable: false,
        },
        company: {
          hidable: false,
        },
      },
    },
  },
  {
    question: 'Tell me about yourself.',
    type: 'TEXT',
    required: true,
    placeholder: 'Some placeholder',
    options: [],
    validations: {
      max: 100,
      min: 10,
      required: true,
    },
    config: {
      uiStyle: 'TEXT', // LONG_TEXT
    },
  },
  {
    question: 'What is your birth date?',
    type: 'DATE',
    required: false,
    placeholder: 'Some placeholder',
    options: [],
    validations: {
      past: true,
      future: false,
      today: true,
      required: true,
    },
    config: {
      uiStyle: 'DATE',
    },
  },
  {
    question: 'What is your birth time?',
    type: 'TIME',
    required: false,
    placeholder: 'Some placeholder',
    options: [],
    validations: {
      required: true,
    },
    config: {
      uiStyle: 'TIME',
    },
  },
];

export const RegistrationForm: React.FC = () => {
  const [form, setForm] = useState(
    new Form(
      'Description',
      'Title',
      [
        new TextField('about', 'Tell me about yourself.', true, 'Enter Name', {
          min: 3,
          max: 20,
        }),
        new TimeField('about', 'Tell me about yourself.', true, 'Enter Name', {
          min: 3,
          max: 20,
        }),
        // new PhoneField(
        //   'phone',
        //   phoneField.question,
        //   'Select',
        //   true,
        //   DROPDOWN_LIST.countryList.map((item) => ({
        //     value: item.value,
        //     label: item.label,
        //   })),
        // ),
        // new ContactInfoField('contactInfo', 'Contact Information', true, '', [
        //   { value: '1', label: '+1 (USA/Canada)' },
        //   { value: '44', label: '+44 (UK)' },
        //   { value: '91', label: '+91 (India)' },
        // ]),
      ],
      'Please fill out this registration form',
    ),
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSubmit = () => {
    // Here you would typically send the data to your backend
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const data: any = []; // collection of form types
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    aiGeneratedData.map((item: any) => {
      if (item?.nested?.length > 0) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        item.nested?.map((item: any) => {
          const formItem = convertJSONToFormItemObject(item);
          if (formItem) {
            data.push(formItem);
          }
        });
      } else {
        const formItem = convertJSONToFormItemObject(item);
        if (formItem) {
          data.push(formItem);
        }
      }
    });
    setForm(
      new Form(
        'Description',
        'Title',
        data,
        'Please fill out this registration form',
      ),
    );
  }, []);

  const handleAddBtn = () => {
    setForm((prevForm) => {
      const newForm = new Form(prevForm.description as string, prevForm.title, [
        ...prevForm.fields,
        new TextField('newField', 'New Field', true, 'Enter new field'),
      ]);
      return newForm;
    });
  };

  const handleDeleteField = (fieldId: string) => {
    setForm((prevForm) => {
      const newFields = prevForm.fields.filter((field) => field.id !== fieldId);
      const newForm = new Form(
        prevForm.description as string,
        prevForm.title,
        newFields,
      );
      return newForm;
    });
  };

  const handleValidate = () => {};

  return (
    <div className="App">
      <FormRenderer form={form} onSubmit={handleSubmit} />
      <button
        onClick={() => {
          handleAddBtn();
        }}
      >
        Add
      </button>
      <button
        onClick={() => {
          handleDeleteField('phone');
          handleDeleteField('Description');
        }}
      >
        Delete ()
      </button>
      <button
        onClick={() => {
          handleValidate();
        }}
      >
        Validate
      </button>
    </div>
  );
};
