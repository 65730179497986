import { gql } from '../../../__generated__';

export const DELETE_FORM = gql(`
 mutation DeleteForm($where: FormUniqueInput!) {
  deleteForm(where: $where) {
    message
  }
}`);

export const DUPLICATE_FORM = gql(`
mutation DuplicateForm($where: FormUniqueInput!) {
  duplicateForm(where: $where) {
    message
  }
}`);
