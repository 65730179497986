import { gql } from '../../../__generated__';

export const GET_SIGNED_URL = gql(`
 query GetProfileImageUploadSignedUrl($data: GetProfileImageUploadSignedUrlInput!) {
  getProfileImageUploadSignedUrl(data: $data) {
    key
    signedUrl
  }
}
`);
