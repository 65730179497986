import { ValidationRule } from '../ValidationRules';

export type IFormField = {
  id: string;
  type: string;
  label: string;
  required: boolean;
  validationRules: ValidationRule[];
  options?: string[];
};

export abstract class FormField {
  constructor(
    // eslint-disable-next-line no-unused-vars
    public id: string,
    // eslint-disable-next-line no-unused-vars
    public type: string,
    // eslint-disable-next-line no-unused-vars
    public label: string,
    // eslint-disable-next-line no-unused-vars
    public required: boolean,
    // eslint-disable-next-line no-unused-vars
    public placeholder: string,
    // eslint-disable-next-line no-unused-vars
    public validationRules: ValidationRule[] = [],
  ) {}

  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-explicit-any
  abstract validate(value: any): string | null;

  toJSON() {
    return {
      id: this.id,
      type: this.type,
      label: this.label,
      required: this.required,
      placeholder: this.placeholder,
    };
  }
}
