import { ValidationRule } from '../ValidationRules';
import { FormField } from './FormField';

export class CheckboxField extends FormField {
  constructor(
    id: string,
    label: string,
    required: boolean,
    placeholder: string,
    validationRules: ValidationRule[] = [],
  ) {
    super(id, 'checkbox', label, required, placeholder, validationRules);
  }

  validate(value: boolean): string | null {
    if (this.required && !value) {
      return 'This field must be checked';
    }
    for (const rule of this.validationRules) {
      const error = rule(value);
      if (error) return error;
    }
    return null;
  }
}
