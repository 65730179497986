import { DotsSixVertical, Plus, X } from '@phosphor-icons/react';
import type { InputRef } from 'antd';
import { Flex, Input, List } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import CommonButton from '../../../components/primitives/CommonButton';
import { ID } from '../../../types/common.type';

type OptionListType = {
  value: string[];
  id: ID;
  // eslint-disable-next-line no-unused-vars
  onChange: (id: ID, key: string[], value: Array<Array<string>>) => void;
};

const OptionList = ({ value, onChange, id }: OptionListType) => {
  const [tags, setTags] = useState<string[]>(value);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);
  const editInputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (value) {
      setTags(value);
    }
  }, [value]);

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    editInputRef.current?.focus();
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag) => tag !== removedTag);
    setTags(newTags);
    onChange(id, ['options'], [newTags]);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
      onChange(id, ['options'], [[...tags, inputValue]]);
    }
    setInputVisible(false);
    setInputValue('');
  };

  const handleEditInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditInputValue(e.target.value);
  };

  const handleEditInputConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    onChange(id, ['options'], [newTags]);
    setEditInputIndex(-1);
    setEditInputValue('');
  };

  const onDragStart = (e: React.DragEvent<HTMLDivElement>, index: string) => {
    e.dataTransfer.setData('text/plain', index);
  };

  const onDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const onDrop = (e: React.DragEvent<HTMLDivElement>, dropIndex: number) => {
    e.preventDefault();
    const dragIndex = Number(e.dataTransfer.getData('text/plain'));
    const newItems = [...tags];
    const [reorderedItem] = newItems.splice(dragIndex, 1);
    newItems.splice(dropIndex, 0, reorderedItem);
    setTags(newItems);
    onChange(id, ['options'], [newItems]);
  };

  return (
    <Flex gap={16} wrap className="option-list" vertical>
      <List
        dataSource={tags}
        locale={{ emptyText: 'No Options' }}
        className="draggable-form-list"
        renderItem={(tag, index: number) => {
          return (
            <List.Item
              key={tag}
              draggable
              onDragStart={(e) => onDragStart(e, index.toString())}
              onDragOver={onDragOver}
              onDrop={(e) => onDrop(e, index)}
            >
              {editInputIndex === index ? (
                <Flex gap={12} key={index} className="w-full" align="center">
                  <DotsSixVertical
                    size={20}
                    color="var(--content-tertiary)"
                    className="flex-shrink-0 cursor-move"
                  />
                  <Input
                    ref={editInputRef}
                    key={tag}
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                    placeholder="Type here..."
                  />
                  <CommonButton
                    type="text"
                    shape="circle"
                    // onClick={() => handleClose(tag)}
                    icon={<X />}
                    shadow={false}
                  />
                </Flex>
              ) : (
                <Flex gap={12} className="w-full" align="center">
                  <DotsSixVertical
                    size={20}
                    color="var(--content-tertiary)"
                    className="flex-shrink-0 cursor-move"
                  />
                  <Input
                    value={tag}
                    onClick={(e) => {
                      setEditInputIndex(index);
                      setEditInputValue(tag);
                      e.preventDefault();
                    }}
                  />
                  {tags?.length > 1 && (
                    <CommonButton
                      type="text"
                      shape="circle"
                      onClick={() => handleClose(tag)}
                      icon={<X />}
                      shadow={false}
                    />
                  )}
                </Flex>
              )}
            </List.Item>
          );
        }}
      />
      {inputVisible ? (
        <Flex gap={12} className="w-full" align="center">
          <DotsSixVertical
            size={20}
            color="var(--content-tertiary)"
            className="flex-shrink-0 cursor-move"
          />
          <Input
            ref={inputRef}
            value={inputValue}
            placeholder="Type here..."
            onChange={handleInputChange}
            onBlur={handleInputConfirm}
            onPressEnter={handleInputConfirm}
          />
          <CommonButton
            type="text"
            shape="circle"
            // onClick={() => handleClose(tag)}
            icon={<X />}
            shadow={false}
          />
        </Flex>
      ) : (
        <CommonButton
          icon={<Plus color="var(--neutrals)" size={14} />}
          onClick={showInput}
          className="pointer w-max-content"
          type="primary"
        >
          Add Option
        </CommonButton>
      )}
    </Flex>
  );
};

export default OptionList;
