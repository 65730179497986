import { ValidationRule } from '../ValidationRules';
import { FormField } from './FormField';
import { PhoneNumber } from './PhoneNumber';

export class PhoneField extends FormField {
  constructor(
    id: string,
    label: string,
    placeholder: string,
    required: boolean,
    // eslint-disable-next-line no-unused-vars
    public countryCodeOptions: { value: string; label: string }[],
    validationRules: ValidationRule[] = [],
  ) {
    super(id, 'phone', label, required, placeholder, [
      ...validationRules,
      (value: PhoneNumber) => {
        if (!value.countryCode) return 'Country code is required';
        if (!value.number) return 'Phone number is required';
        if (!/^\d+$/.test(value.number))
          return 'Phone number should contain only digits';
        return null;
      },
    ]);
  }

  validate(value: PhoneNumber): string | null {
    for (const rule of this.validationRules) {
      const error = rule(value);
      if (error) return error;
    }
    return null;
  }

  toJSON() {
    return {
      ...super.toJSON(),
      countryCodeOptions: this.countryCodeOptions,
    };
  }
}
