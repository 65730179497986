import { ConfigProvider, Radio, ThemeConfig } from 'antd';
import { RadioButtonProps } from 'antd/es/radio/radioButton';

type BoxType = 'primary' | 'secondary';

export default function CommonRadio({
  children,
  boxType,
  ...restProps
}: RadioButtonProps & { boxType: BoxType }) {
  const config: Record<string, ThemeConfig> = {
    primary: {
      token: { colorPrimary: '#343b3f' },
    },
    secondary: {
      token: { colorPrimary: '#F3F5F6' },
    },
  };

  return (
    <ConfigProvider theme={config?.[boxType]}>
      <Radio {...restProps} className={boxType}>
        {children}
      </Radio>
    </ConfigProvider>
  );
}
