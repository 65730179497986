import { Button, Col, Flex, Row } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';
import Title from 'antd/es/typography/Title';
import {
  FormSchemaType,
  PageLayoutType,
  Palette,
} from '../../../form-builder/model/form/form.types';

export default function PagePreview({
  item,
  activeTheme,
  handleCTAClick,
  loading,
}: {
  item: FormSchemaType;
  activeTheme: Palette;
  loading?: boolean;
  handleCTAClick: () => void;
}) {
  const renderPage = () => {
    const imgUrl = item?.bg_image_url;
    switch (item.page_layout) {
      case PageLayoutType.PAGE_TOP_CENTER:
        return (
          <Flex className="page-top-center" vertical align="center" gap={32}>
            {imgUrl ? (
              <img className="page-image" src={imgUrl} />
            ) : (
              <Paragraph className="mb-0 text-center">
                No Preview Image
              </Paragraph>
            )}
            <Flex gap={12} vertical>
              <Title
                className="page-title"
                style={{ color: activeTheme.textColor }}
              >
                {item?.title}
              </Title>
              <Paragraph
                className="page-body mb-0"
                style={{ color: activeTheme.textColor }}
              >
                {item?.body}
              </Paragraph>
            </Flex>
            {item?.cta_name && (
              <Button
                type="default"
                size="large"
                shape="round"
                onClick={() => handleCTAClick()}
                loading={loading}
              >
                {item?.cta_name}
              </Button>
            )}
          </Flex>
        );
      case PageLayoutType.PAGE_FILLED:
        return (
          <Flex className="page-top-center" vertical align="center" gap={32}>
            <Flex gap={12} vertical align="center">
              <Title
                className="page-title"
                style={{ color: activeTheme.textColor }}
              >
                {item?.title}
              </Title>
              <Paragraph
                className="page-body mb-0"
                style={{ color: activeTheme.textColor }}
              >
                {item?.body}
              </Paragraph>
            </Flex>
            {item?.cta_name && (
              <Button
                type="default"
                size="large"
                shape="round"
                onClick={() => handleCTAClick()}
                loading={loading}
              >
                {item?.cta_name}
              </Button>
            )}
          </Flex>
        );
      case PageLayoutType.PAGE_LEFT:
        return (
          <Row
            className="page-left"
            align="middle"
            justify="space-between"
            gutter={[48, 48]}
          >
            <Col span={12}>
              {imgUrl ? (
                <img className="page-image" src={imgUrl} />
              ) : (
                <Paragraph className="mb-0 text-center">
                  No Preview Image
                </Paragraph>
              )}
            </Col>
            <Col span={12}>
              <Flex gap={32} vertical justify="center" align="flex-start">
                <Flex gap={12} vertical>
                  <Title
                    className="page-title"
                    style={{ color: activeTheme.textColor }}
                  >
                    {item?.title}
                  </Title>
                  <Paragraph
                    className="page-body mb-0"
                    style={{ color: activeTheme.textColor }}
                  >
                    {item?.body}
                  </Paragraph>
                </Flex>
                {item?.cta_name && (
                  <Button
                    type="default"
                    size="large"
                    shape="round"
                    onClick={() => handleCTAClick()}
                    loading={loading}
                  >
                    {item?.cta_name}
                  </Button>
                )}
              </Flex>
            </Col>
          </Row>
        );

      default:
        return null;
    }
  };
  return <div className="welcome-page-wrapper">{renderPage()}</div>;
}
